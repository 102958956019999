import getConfig from 'config';
import store from 'redux/store'
import { logout } from 'Actions';
import Cookies from 'js-cookie';

export const constructUrl = (name, proxy = true) => {

    let url = getConfig().API_BASE_URL;
    if (proxy) {
        url = url + "/rest/avila";
    }

    return url + name;
}

export const getToken = () => {
    const { auth } = store.getState();
    return auth.token || null;
}

export const checkTimeout = () => {

    const tokenTimeout = parseInt(Cookies.get('token-timeout'), 10);
    const currentTimeSecond = (new Date()).getTime() / 1000;
    
    if (currentTimeSecond > tokenTimeout) {
        console.log("timeout")
        store.dispatch(logout())
    } else {
        console.log("pas de timeout")
    }
}



export const fetchApi = async (_url, requestOptions) => {

    let url = _url;

    const token = Cookies.get('token');
    const tokenTimeout = parseInt(Cookies.get('token-timeout'), 10);
    const currentTimeSecond = (new Date()).getTime() / 1000;

    console.log("tokenTimeout : ", tokenTimeout, "currentTimeSecond : ", currentTimeSecond, "delta : ", tokenTimeout - currentTimeSecond )

    if (currentTimeSecond > tokenTimeout) {
        console.log("timeout")
    }

    if (token && currentTimeSecond < tokenTimeout) {
        let separator = "?";
        if (url.indexOf("?") !== -1) {
            separator = "&"
        }

        url = url + separator + "token=" + token
    }

    const responseRs = await fetch(url, requestOptions)
    const response = responseRs.clone();
    const responseJson = await response.json();

    if (responseJson.unauthorized) {
        console.log("unauthorized")
        store.dispatch(logout())
        return await new Promise(resolve => {});
    } else {
        let time = Math.trunc(currentTimeSecond)
        console.log("set cookies fetchApi : ", String(time + (60)))
        Cookies.set('token-timeout', String(time + (3600)));
    }

    return responseRs;
}

export const getResult = (resultJson, name) => {
    if (resultJson.content) {
        return resultJson.content[name]
    }

    return resultJson;
}

export const apiCall = async (name, querystring = "") => {
    const result = await fetchApi(constructUrl(name) + querystring, {referrerPolicy: "no-referrer"});
    const resultJson = await result.json();

    return getResult(resultJson, name);
}

