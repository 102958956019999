import { apiCall, constructUrl, getResult, fetchApi as fetch } from './ApiCall';
import { statusStringFromArray, statusArrayFromString } from 'Util/OeuvreStatus'
import { cleanBigNumber } from 'Util/Divers'
import { displayOeuvreLastProprio, getOeuvreLastProprioId } from 'Util/OeuvreProprietaire';
import { getOeuvreElements, getOeuvreMateriaux, getOeuvreCouleurs, getOeuvreFamilles, critereArrayFromString, critereStringFromArray } from 'Util/OeuvreCriteres';
import { calcMultipleDatas, multipleWithNumero } from 'Util/OeuvreMultiple'
import Moment from 'moment';


const constructOeuvre = oeuvre => ({
    ...oeuvre,
    couleurs: (oeuvre.couleurs || []).map(item => item.objectid),
    materiaux: (oeuvre.materiaux || []).map(item => item.objectid),
    elements: (oeuvre.elements || []).map(item => item.objectid),
    familles: (oeuvre.familles || []).map(item => item.objectid),
    etat_fiche: statusArrayFromString(oeuvre.etat_fiche),
    elements_array: critereArrayFromString(oeuvre.elements_string, getOeuvreElements()),
    materiaux_array: critereArrayFromString(oeuvre.materiaux_string, getOeuvreMateriaux()),
    familles_array: critereArrayFromString(oeuvre.familles_string, getOeuvreFamilles()),
    couleurs_array: critereArrayFromString(oeuvre.couleurs_string, getOeuvreCouleurs()),

})


export const getAllOeuvres = async ({ cursor, limit } = {}) => {
    return await apiCall("getalloeuvres")
}

export const getFullOeuvres = async ({ cursor, limit } = {}) => {
    return await apiCall("getfulloeuvres")
}

export const getOeuvreById = async ({ id }) => {
    const rs = await apiCall("getoeuvre", "?oeuvre_id=" + id)
    if (rs.oeuvre && rs.oeuvre.length > 0) {
        return {
            oeuvre: constructOeuvre(rs.oeuvre[0])
        }
    }

    return null;
}

export const deleteOeuvre = async ({ oeuvre }) => {

  /*  const response =*/ await apiCall("deleteoeuvre", "?oeuvre_id=" + oeuvre.objectid)
  //  const responseJson = await response.json();

  //  return getResult(responseJson, 'deleteoeuvre');
}

export const createOeuvre = async ({ data }) => {

    const url = constructUrl("createoeuvre");

    var formdata = new FormData();
    formdata.append("oeuvre", JSON.stringify({oeuvreData: data}));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'createoeuvre');
}

export const updateOeuvre = async ({ id, data }) => {
    const url = constructUrl("updateoeuvre");
    var formdata = new FormData();
    formdata.append("oeuvre_id", id);

    [
        'biblios', 'consignations', 'dessins', 'medias', 'photos', 'expositions',
        'estimations','encheres', 'historiques', 'soeurs',
        'authentifications','assurances','caisse_transports', 'localisations', 'multiples',
        'photo_items', 'oeuvre_bibs', 'oeuvre_meds', 'oeuvre_expos'
    ].forEach(attr => {
        if (data[attr]) {
            data[attr] = data[attr].map(item => item.objectid);
        }
    })
 
    data['etat_fiche'] = statusStringFromArray(data['etat_fiche'])
    data['elements_string'] = critereStringFromArray(data['elements_array'], getOeuvreElements())
    data['materiaux_string'] = critereStringFromArray(data['materiaux_array'], getOeuvreMateriaux())
    data['familles_string'] = critereStringFromArray(data['familles_array'], getOeuvreFamilles())
    data['couleurs_string'] = critereStringFromArray(data['couleurs_array'], getOeuvreCouleurs())

    let date = new Date()
    data['date_update'] = Moment(date).format('Y-M-D')

    formdata.append("oeuvre", JSON.stringify({oeuvreData: data}));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'updateoeuvre');
}



//
//
//
export const createMultipleUpdateOeuvre = async ( {oeuvre, data} ) => {

    const resCreate = await createMultiple({oeuvreid:oeuvre.objectid, data});
    const objectId = resCreate["objectId"]

    oeuvre = {
        ...oeuvre,
        multiples: [
            ...oeuvre.multiples,
            {"objectid" : objectId}
        ]
    }

    await updateOeuvre({ id: oeuvre.objectid, data: oeuvre });
}

export const createMultipleAndLocalisationUpdateOeuvre = async ({ oeuvre, data }) => {

    data.derniere_localisation = data.localisation
    data.dernier_proprietaire = data.proprietaire

    const resCreate = await createMultiple({oeuvreid:oeuvre.objectid, data});
    const multipleObjectId = resCreate["objectId"]

    data["multiple"] = data["numero"]
    data["remarque"] = data["remarque_localisation"]
    const resLocCreate = await createLocalisation({oeuvreid:oeuvre.objectid, data});
    const localisationObjectId = resLocCreate["objectId"]

    oeuvre = {
        ...oeuvre,
        multiples: [
            ...oeuvre.multiples,
            {"objectid" : multipleObjectId}
        ]
    }
    oeuvre = {
        ...oeuvre,
        localisations: [
            ...oeuvre.localisations,
            {"objectid" : localisationObjectId}
        ]
    }

    await updateOeuvre({ id: oeuvre.objectid, data: oeuvre });
}

export const createMultiple = async ({ oeuvreid, data }) =>
{
    const url = constructUrl("createmultiple");
    var formdata = new FormData();
    data["oeuvres"] = [oeuvreid]

    formdata.append("multiple", JSON.stringify({ multipleData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'createmultiple');
}




export const updateMultiple = async ({oeuvreid, data }) => {
    const url = constructUrl("updatemultiple");

    var formdata = new FormData();
    formdata.append("multiple_id", data.objectid);

    data["oeuvres"] = [oeuvreid]
    formdata.append("multiple", JSON.stringify({ multipleData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'updatemultiple');
}

//
//
//
export const createEnchereUpdateOeuvre = async ( {oeuvre, data} ) => {

    const resCreate = await createEnchere({oeuvreid:oeuvre.objectid, data});
    const objectId = resCreate["objectId"]

    oeuvre = {
        ...oeuvre,
        encheres: [
            ...oeuvre.encheres,
            {"objectid" : objectId}
        ]
    }

    await updateOeuvre({ id: oeuvre.objectid, data: oeuvre });
}

export const updateEnchere = async ({oeuvreid, data }) => {
    const url = constructUrl("updateenchere");

    var formdata = new FormData();
    formdata.append("enchere_id", data.objectid);

    data["oeuvres"] = [oeuvreid]
    data["estimation_min"] = cleanBigNumber(data.estimation_min)
    data["estimation_max"] = cleanBigNumber(data.estimation_max)
    data["prix_marteau"] = cleanBigNumber(data.prix_marteau)
    data["prix_de_vente"] = cleanBigNumber(data.prix_de_vente)

    formdata.append("enchere", JSON.stringify({ enchereData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'updateenchere');
}

export const createEnchere = async ({ oeuvreid, data }) => {
    const url = constructUrl("createenchere");

    var formdata = new FormData();
    data["oeuvres"] = [oeuvreid]
    data["estimation_min"] = cleanBigNumber(data.estimation_min)
    data["estimation_max"] = cleanBigNumber(data.estimation_max)
    data["prix_marteau"] = cleanBigNumber(data.prix_marteau)
    data["prix_de_vente"] = cleanBigNumber(data.prix_de_vente)

    formdata.append("enchere", JSON.stringify({ enchereData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'createenchere');
}


//
//
//
export const createAssuranceUpdateOeuvre = async ( {oeuvre, data} ) => {

    const resCreate = await createAssurance({oeuvreid:oeuvre.objectid, data});
    const objectId = resCreate["objectId"]

    oeuvre = {
        ...oeuvre,
        assurances: [
            ...oeuvre.assurances,
            {"objectid" : objectId}
        ]
    }

    await updateOeuvre({ id: oeuvre.objectid, data: oeuvre });
}

export const createAssurance = async ({ oeuvreid, data }) => {
    const url = constructUrl("createassurance");
    

    var formdata = new FormData();
    data["oeuvres"] = [oeuvreid]
    data["valeur"] = cleanBigNumber(data.valeur)


    formdata.append("assurance", JSON.stringify({ assuranceData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'createassurance');
}

export const updateAssurance = async ({oeuvreid, data }) => {
    const url = constructUrl("updateassurance");

    var formdata = new FormData();
    formdata.append("assurance_id", data.objectid);
    data["oeuvres"] = [oeuvreid]
    data["valeur"] = cleanBigNumber(data.valeur)

    formdata.append("assurance", JSON.stringify({ assuranceData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'updateassurance');
}



//
//
//

export const createTransportUpdateOeuvre = async ( {oeuvre, data} ) => {

    const resCreate = await createTransport({oeuvreid:oeuvre.objectid, data});
    const objectId = resCreate["objectId"]

    oeuvre = {
        ...oeuvre,
        caisse_transports: [
            ...oeuvre.caisse_transports,
            {"objectid" : objectId}
        ]
    }

    await updateOeuvre({ id: oeuvre.objectid, data: oeuvre });
}

export const createTransport = async ({ oeuvreid, data }) => {
    const url = constructUrl("createcaissetransport");

    var formdata = new FormData();
    data["oeuvres"] = [oeuvreid]
    formdata.append("caisse_transport", JSON.stringify({ caisse_transportData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'createcaisse_transport');
}


export const updateTransportUpdateOeuvre = async ({ oeuvreid, data, oeuvre }) => {

    await updateTransport({oeuvreid, data});
    await updateOeuvre({ id: oeuvreid, data: {...oeuvre} });
}

export const updateTransport = async ({ oeuvreid, data }) => {
    const url = constructUrl("updatecaissetransport");

    var formdata = new FormData();
    formdata.append("caisse_transport_id", data.objectid);
    data["oeuvres"] = [oeuvreid]
    formdata.append("caisse_transport", JSON.stringify({ caisse_transportData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'updatecaisse_transport');
}



//
//
//

export const createAuthentificationUpdateOeuvre = async ( {oeuvre, data} ) => {

    const resCreate = await createAuthentification({oeuvreid:oeuvre.objectid, data});
    const objectId = resCreate["objectId"]

    oeuvre = {
        ...oeuvre,
        authentifications: [
            ...oeuvre.authentifications,
            {"objectid" : objectId}
        ]
    }

    await updateOeuvre({ id: oeuvre.objectid, data: oeuvre });
}

export const createAuthentification = async ({ oeuvreid, data }) => {
    const url = constructUrl("createauthentification");

    var formdata = new FormData();
     data["oeuvres"] = [oeuvreid]
    formdata.append("authentification", JSON.stringify({ authentificationData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'createauthentification');
}

export const updateAuthentification = async ({ oeuvreid, data }) => {
    const url = constructUrl("updateauthentification");

    var formdata = new FormData();
    formdata.append("authentification_id", data.objectid);
    data["oeuvres"] = [oeuvreid]
    formdata.append("authentification", JSON.stringify({ authentificationData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'updateauthentification');
}



//
//
//
export const deleteHistorique = async ({ historique }) => {

  /*  const response =*/ await apiCall("deletehistorique", "?historique_id=" + historique.objectid)
  //  const responseJson = await response.json();

  //  return getResult(responseJson, 'deleteoeuvre');
}


export const createHistoriqueUpdateOeuvre = async ( {oeuvre, data} ) => {

    const resCreate = await createHistorique({oeuvreid:oeuvre.objectid, data});
    const objectId = resCreate["objectId"]

    oeuvre = {
        ...oeuvre,
        historiques: [
            ...oeuvre.historiques,
            {"objectid" : objectId}
        ]
    }

    let oeuvreId =  oeuvre.objectid

    await updateOeuvre({ id: oeuvre.objectid, data: oeuvre });

    let dataOeuvre = await getOeuvreById({id : oeuvreId})
    var newOeuvre = dataOeuvre.oeuvre 

    newOeuvre.dernier_proprietaire_id = getOeuvreLastProprioId(newOeuvre)
    newOeuvre.dernier_proprietaire = displayOeuvreLastProprio(newOeuvre)

    await updateOeuvre({ id: oeuvreId, data: newOeuvre });
}

export const updateHistoriqueUpdateOeuvre = async ( {oeuvre, data, isMultiple} ) => {

    const historique = {...data}
    await updateHistorique({oeuvreid:oeuvre.objectid, data});
    await updateOeuvre({ id: oeuvre.objectid, data: {...oeuvre} });

    let loadOeuvre = await getOeuvreById({id : oeuvre.objectid})
    let dataOeuvre = loadOeuvre.oeuvre
    

    if ( isMultiple === true ) {
        calcMultipleDatas(dataOeuvre)

      //  console.log("multipleExemplaire : ", historique)
        const multipleExemplaire = historique.multiple
        const multiple = multipleWithNumero(dataOeuvre, multipleExemplaire)

        await updateMultiple({ oeuvreid: dataOeuvre.objectid, data: {...multiple} });

    } else {

        oeuvre.dernier_proprietaire_id = getOeuvreLastProprioId(dataOeuvre)
        oeuvre.dernier_proprietaire = displayOeuvreLastProprio(dataOeuvre)
    }

    await updateOeuvre({ id: dataOeuvre.objectid, data: {...dataOeuvre} });
}

export const createHistorique = async ({ oeuvreid, data }) => {
    const url = constructUrl("createhistorique");

    var formdata = new FormData();
    data["oeuvres"] = [oeuvreid]

    if ( data["ancien_proprio"] && data["ancien_proprio"].objectid ) {
        data["ancien_proprio"] = data["ancien_proprio"].objectid
    }
    if ( data["nouveau_proprio"] && data["nouveau_proprio"].objectid ) {
        data["nouveau_proprio"] = data["nouveau_proprio"].objectid
    }
    formdata.append("historique", JSON.stringify({ historiqueData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'createhistorique');
}

export const updateHistorique = async ({ oeuvreid, data }) => {
    const url = constructUrl("updatehistorique");

    var formdata = new FormData();
    formdata.append("historique_id", data.objectid);
    data["oeuvres"] = [oeuvreid]
    
    if ( data["ancien_proprio"] && data["ancien_proprio"].objectid ) {
        data["ancien_proprio"] = data["ancien_proprio"].objectid
    } else {
        data["ancien_proprio"] = null
    }
    if ( data["nouveau_proprio"] && data["nouveau_proprio"].objectid ) {
        data["nouveau_proprio"] = data["nouveau_proprio"].objectid
    } else {
        data["nouveau_proprio"] = null
    }
    formdata.append("historique", JSON.stringify({ historiqueData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'updatehistorique');
}


//
//
//
export const createLocalisationUpdateOeuvre = async ( {oeuvre, data} ) => {

    const resCreate = await createLocalisation({oeuvreid:oeuvre.objectid, data});
    const objectId = resCreate["objectId"]

    oeuvre = {
        ...oeuvre,
        localisations: [
            ...oeuvre.localisations,
            {"objectid" : objectId}
        ]
    }

    await updateOeuvre({ id: oeuvre.objectid, data: oeuvre });
}

export const createLocalisation = async ({ oeuvreid, data }) => {
    const url = constructUrl("createlocalisation");

    var formdata = new FormData();
    data["oeuvres"] = [oeuvreid]
    formdata.append("localisation", JSON.stringify({ localisationData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'createlocalisation');
}

export const updateLocalisationUpdateOeuvre = async ({ oeuvreid, data, oeuvre }) => {

    await updateLocalisation({oeuvreid, data});
    await updateOeuvre({ id: oeuvreid, data: {...oeuvre} });
}

export const updateLocalisation = async ({ oeuvreid, data }) => {
    const url = constructUrl("updatelocalisation");

    var formdata = new FormData();
    formdata.append("localisation_id", data.objectid);
    data["oeuvres"] = [oeuvreid]

    formdata.append("localisation", JSON.stringify({ localisationData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'updatelocalisation');
}


