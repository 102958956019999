// @flow
import React from 'react';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


const top100Films = [
    { label: 'The Shawshank Redemption', value: '1994' },
    { label: 'The Godfather', value: '1972' }
]

type OptionValue = Array<{ label: string, value: string }>
type Props = {
    style?: any,
    label?: string,
    placeholder?: string,

    options: OptionValue,
    onChange: (value: OptionValue) => any,
    value: OptionValue
}
export default ({
    style = {},
    label,
    placeholder,

    options = top100Films,

    onChange = () => {},
    value = []

}: Props ) => {

 
  return (
    <Autocomplete
      multiple
      value={value}
      onChange={(event, newValue) => {
       // onChange(newValue);
      }}
      options={[]}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => {
        return option.value === value.value;
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option.label}
            {...getTagProps({ index })}
          />
        ))
      }
      style={style}
      renderInput={(params) => (
        <TextField {...params} size="small" label={label} variant="outlined" placeholder={placeholder}  />
      )}
    />
  );
}