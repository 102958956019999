import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { createMedia } from 'Api'
import MediaLayout from './index';
import { CircularProgress } from '@material-ui/core'
import { checkTimeout } from 'Api/ApiCall'

export default () => {

    const history = useHistory();
    const [ loading, setLoading ] = useState(true);

    const loadData = async () => {
        checkTimeout()
        setLoading(false);
    }

    useEffect(() => {
        loadData();
    })


    let content = null;
    if (loading) {
        content = <CircularProgress />
    } else {
        content = (
        <MediaLayout
            layout={"create"}
            saveData={async data => {

              //  console.log("createMedia : ", data)
                const result = await createMedia({ data });
                return result;
            }}
            onSaved={media => {
                history.push("/media/" + media.objectId);
            }}
        />
        )
    }

    return (
        <>
           {content}
       </>
   )
}