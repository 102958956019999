// @flow
import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import PageLayout from 'layouts/Helpers/PageLayout'
import Prompt from 'components/Prompt';


import {
    Button
} from '@material-ui/core'


import DescriptionPanel from './Description';
import { useTranslation } from 'react-i18next';
import { deleteBiblio, createPhotoItem } from 'Api'
import { useCurrentPermissionProfile } from 'redux/hooks';


type Props = {
    initialData: any,
    saveData: any,
    onSaved: any,
    reloadData: any => any,
    layout: 'edit' | 'create' | 'search'
}


const defaultTitle = "Nouvelle biblio/presse";

export default ({ initialData, reloadData = () => {}, onChange = () => {}, onSaved = async () => {}, onDelete = async () => {}, saveData = async () => {}, layout = 'edit'  }: Props) => {

    const { t } = useTranslation()

    const [ changed, setChanged ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ data, _setData ] = useState(initialData || {})

    const { enqueueSnackbar } = useSnackbar();
    
    useEffect(() => {
        _setData(initialData || {});
    }, [ initialData ])
    
    const onClickCancel = () => {
        _setData(initialData || {});
        setChanged(false);
    }

    async function deleteBiblioAsync(biblio) {
        await deleteBiblio({ biblio });
        onDelete()
    }

    const onClickDelete = () => {

        if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
            deleteBiblioAsync(data)
        }
    }

    const onClickSave = async () => {
        if (!saveData) {
            return;
        }
        setLoading(true);

        let result;
        try {
            let photo_items = (data.photo_items || []);
            const createPhotoPromises = photo_items.map(async (photo_item, index) => {
                if (!photo_item.objectid) {
                    const { objectId } = await createPhotoItem({ data: photo_item })
                    photo_items[index] = {
                        objectid: objectId,
                        ...photo_item
                    };
                }
            })
            await Promise.all(createPhotoPromises);
            
            result = await saveData({ ...data })
            enqueueSnackbar("Sauvegardé", {
                variant: 'success'
            })
            setChanged(false);

        } catch (e) {
            alert("une erreur est survenue: " + e.reason)
        }
        setLoading(false);
        onSaved(result);
    }

    const canSave = () => {
        if (!changed || loading) {
            return false;
        }

        return true;
    }

    const setData = data => {
        setChanged(true);
        _setData(data);
    }
    
    const layoutIsEdit = layout === 'edit';
    const permissionProfile = useCurrentPermissionProfile();
    const allowedToSave = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === 'adriana' || permissionProfile === 'avila' || permissionProfile === null;
    const allowedToDelete = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === 'adriana' || permissionProfile === 'avila' || permissionProfile === null;

    var title = "Biblio "
    
    if ( data.archive ) {
        title = title + data.archive
    }

    return (

        <PageLayout title={(layoutIsEdit ? title : defaultTitle)} actions={(

            <div>

                {saveData && allowedToSave && <>
                    <Button
                    disabled={!canSave()} color="primary"
                        onClick={onClickCancel} style={{marginRight: '10px'}}
                    >{t("cancel").toUpperCase()}</Button>
                    <Button
                        style={{marginRight: '10px'}}
                        disabled={!canSave()} variant="contained" color="primary"
                        onClick={onClickSave}
                    >{t("save").toUpperCase()}</Button>
                </>}
                { allowedToDelete && <Button
                    style={{marginLeft: '100px'}}
                    color="primary"
                     variant="contained" 
                    onClick={onClickDelete}
                >{t("delete").toUpperCase()}</Button>}
            </div>
            )}>


            <Prompt when={changed===true} message={t("alerte_change_page")}/>

            <DescriptionPanel data={data} reloadData={reloadData}  onChange={setData}  layout={layout}/>

        </PageLayout>

    )
}
