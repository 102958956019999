import React from 'react';
import DataTable from 'components/DataTable'
import {
    Edit as EditIcon,
    Delete as DeleteIcon
} from '@material-ui/icons'

import { useHistory } from 'react-router-dom'
import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';

import Contacts from 'data/Contacts';

import { useCurrentPermissionProfile } from 'redux/hooks';
import { deleteContact } from 'Api'

const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = Contacts, actions, onChange, onClickEdit }) => {

    const history = useHistory();
    const permissionProfile = useCurrentPermissionProfile();
    const canDelete = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === 'adriana' || permissionProfile === 'avila' || permissionProfile === null;
    const canEdit = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === 'adriana' || permissionProfile === 'avila' || permissionProfile === 'regisseur' || permissionProfile === null;


    async function deleteContactAsync(contact) {

        await deleteContact({ contact });
        onChange(data.filter(e => e.objectid !== contact.objectid))
    }
    
    return (
        <Paper elevation={3}>
            <DataTable
                data={data}
                config={{
                    onClickRow: contact => {
                        history.push("/contact/" + contact.objectid);
                    },
                    cells: [
                        { title: 'Structure', key: 'structure' },
                        { title: 'Nom', key: 'nom' },
                        { title: 'Prénom', key: 'prenom' },
                        { title: 'Genre', key: 'genre' },
                    ],
                    actions: actions || [
                        ...(canEdit ? [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, contact) => {
                                window.open("/contact/" + contact.objectid);
                            }
                        }, ] : []),
                        ...(canDelete ? [
                        {   
                            icon: <DeleteIcon />, title: "Delete",
                            onClick: (id, contact) => {  
                                if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                    deleteContactAsync(contact)
                                }
                            }
                        } ] : [])
                    ]
                }}
                getCell={(item, attr_name) => {
                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>


    )
}