import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { createExpo } from 'Api'
import ExpoLayout from './index';
import { CircularProgress } from '@material-ui/core'
import { checkTimeout } from 'Api/ApiCall'

export default () => {

    const history = useHistory();
    const [ loading, setLoading ] = useState(true);

    const loadData = async () => {
        checkTimeout()
        setLoading(false);
    }

    useEffect(() => {
        loadData();
    })

    let content = null;
    if (loading) {
        content = <CircularProgress />
    } else {
        content = (
        <ExpoLayout
            layout="create"
            saveData={async data => {

                const result = await createExpo({ data });
                return result;
            }}
            onSaved={expo => {
                history.push("/expo/" + expo.objectId);
            }}
        />
        )
    }

    return (
        <>
           {content}
       </>
   )
}