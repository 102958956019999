import React, { useState } from 'react';
import styled from 'styled-components';
import {
    Edit as EditIcon
} from '@material-ui/icons'


import Helmet from 'react-helmet';
import {
    Grid,
    Button, CircularProgress
} from '@material-ui/core'


import SearchOeuvre from 'Api/Search/oeuvre';
import SearchMultiple from 'Api/Search/multiple';
import SearchPanel from './searchPanel';
import ListLayout from '../List/layout_search'
import ListMultipleLayout from '../List/layout_search_multiple'


import {
    FlexRowSpaceBetween
} from 'layouts/Helpers/flexbox'
import {
    Title, Divider,
    Container
} from 'layouts/Helpers/page'
import { useTranslation } from 'react-i18next';

import { saveAs } from 'file-saver'
import { exportListeExterne, exportListeInterne, exportRegisseurInterne, exportRegisseurExterne, exportMultiplesDisponibles, exportValeurAssurance, exportPrix, exportCollectionneur, exportSansPhoto, exportInventaire} from 'Util/OeuvreExport'
import { useSearch, useMutipleSearch } from 'redux/hooks';
import ExportListSelector from 'components/Selectors/ExportList'
import { completeListe } from 'Util/OeuvreMultipleSearch'

import { useCurrentPermissionProfile } from 'redux/hooks';

import Tabs, {
    TabsContainer, TabsPanels as OriginalTabsPanels
} from 'components/Tabs'

const TabsPanels = styled(OriginalTabsPanels)`
    padding:20px;
    padding-top: 0px;
`

export default () => {

    const { t } = useTranslation();
    const objectType = 'artwork';
    const permissionProfile = useCurrentPermissionProfile();
    const userIsComitee = permissionProfile === 'comite' || permissionProfile === 'comite_consultation' || permissionProfile === 'admin' || permissionProfile === null;
    var exportList = []
    
    if ( userIsComitee === true ) {
        exportList=[{"value":"simple_interne", "name":"Liste simple interne"},
                        {"value":"simple_externe", "name":"Liste simple externe"},
                        {"value":"regisseur_interne", "name":"Liste régisseur interne"},
                        {"value":"regisseur_externe", "name":"Liste régisseur externe"},
                        {"value":"multiples_disponibles", "name":"Liste multiples disponibles"},
                        {"value":"valeur_d_assurance", "name":"Liste Assurance"},
                        {"value":"prix", "name":"Liste prix"},
                        {"value":"collectionneur", "name":"Liste collectionneur"},
                        {"value":"sans_photo", "name":"Liste sans photo"},
                        {"value":"inventaire", "name":"Liste inventaire"}]
    } else {
        exportList=[{"value":"simple_interne", "name":"Liste simple interne"},
                        {"value":"simple_externe", "name":"Liste simple externe"},
                        {"value":"regisseur_interne", "name":"Liste régisseur interne"},
                        {"value":"regisseur_externe", "name":"Liste régisseur externe"},
                        {"value":"multiples_disponibles", "name":"Liste multiples disponibles"},
                        {"value":"collectionneur", "name":"Liste collectionneur"},
                        {"value":"sans_photo", "name":"Liste sans photo"},
                        {"value":"inventaire", "name":"Liste inventaire"}]
    }

    async function exportOeuvre(selectedList, oeuvres, multiples) {

        const ExcelJS = require('exceljs');
        const wb = new ExcelJS.Workbook()
        const ws = wb.addWorksheet()
        var filename = ""

        ws.defaultRowHeight = 100

        if ( selectedList === "simple_interne" || (selectedList === null )  ) {
            filename = await exportListeInterne(wb, ws, oeuvres)

        } else if ( (selectedList === "simple_externe")) {
            filename = await exportListeExterne(wb, ws, oeuvres)

        } else if ( selectedList === "regisseur_interne" ) {
            filename = await exportRegisseurInterne(wb, ws, oeuvres)

        } else if ( selectedList === "regisseur_externe" ) {
            filename = await exportRegisseurExterne(wb, ws, oeuvres)

        } else if ( selectedList === "multiples_disponibles" ) {
            filename = await exportMultiplesDisponibles(wb, ws, multiples)

        } else if ( selectedList === "valeur_d_assurance" ) {
            filename = await exportValeurAssurance(wb, ws, oeuvres)

        } else if ( selectedList === "prix" ) {
            filename = await exportPrix(wb, ws, oeuvres)

        } else if ( selectedList === "collectionneur" ) {
            filename = await exportCollectionneur(wb, ws, oeuvres)

        } else if ( selectedList === "sans_photo" ) {
            filename = await exportSansPhoto(wb, ws, oeuvres)

        } else if ( selectedList === "inventaire" ) {
            filename = await exportInventaire(wb, ws, oeuvres)
        }

        const buf = await wb.xlsx.writeBuffer()

        saveAs(new Blob([buf]), filename + '.xlsx')
    }



    const [ loading, setLoading ] = useState(false);
    const [ searchObject, setSearchForm, setSearchResults ] = useSearch(objectType);
    const [ searchMultipleObject, setMultipleSearchForm, setMultipleSearchResults] = useMutipleSearch("multiple");

    const [ selectedList, setSelectedList] = useState(null);

    const _clean = () => {
        
        setSearchForm({})
        setMultipleSearchForm({})

    }

    const _search = async () => {

        if (!searchObject.form) {
            window.alert("Entrez un critère");
            return;
        }

        setLoading(true);

        const searchResult = await SearchOeuvre({ data: searchObject.form })

        if (searchResult.error) {
            window.alert(searchResult.error);
            setLoading(false);
            return;
        }

        const result = searchResult.data.filter(o => !!o.objectid);

        setLoading(false);

        setSearchResults(result);
    //    console.log("result", result)

        const searchMultipleResult = await SearchMultiple({ data: searchObject.form })

        var resultMultiple = []
        
        if ( searchMultipleResult && searchMultipleResult.data ) {
            resultMultiple = searchMultipleResult.data.filter(o => !!o.objectid);
        }

       // var liste = completeMultipleListe(resultMultiple)
        var liste = completeListe(resultMultiple, result)
    //    console.log("liste", liste)

        setMultipleSearchResults(liste);

    }

    if (searchObject && searchObject.results) {

        const data = searchObject.results
        
        var dataMultiple = []
        if (searchMultipleObject && searchMultipleObject.results) {
            dataMultiple = searchMultipleObject.results
        }

        return (
            <React.Fragment>
                <Helmet title={"Resultats"} />

                <FlexRowSpaceBetween>

                    <Button
                        variant="contained" color="primary"
                        onClick={() => {setSearchResults(null)}}
                    >{t("back").toUpperCase()}</Button>

                    <div>
                    {exportList && <ExportListSelector
                        style={{ width: '200px', marginRight:'50px' }}
                        size="small"
                        variant='outlined'
                        list={exportList}
                        value={selectedList}
                        onChange={setSelectedList}
                     />}

                    <Button
                        variant="contained" color="primary"
                        onClick={() => exportOeuvre(selectedList, searchObject.results, dataMultiple)}
                    >{t("export").toUpperCase()}</Button>
                    </div>

                </FlexRowSpaceBetween>

                <Divider my={6} />

                <Grid container spacing={2} style={{ width: '100%' }}>
                    <Grid item xs={12}>
                        <Container>
                            <TabsContainer orientation={"horizontal"}>
                                <Tabs
                                    orientation={"horizontal"}
                                    variant="scrollable"
                                    tabs={[

                                        {
                                            label: "Oeuvres",
                                            panel: (
                                                <TabsPanels>
                                        
                                                    <Title>
                                                        {data.length} {t('results')}
                                                    </Title>
                                                    <ListLayout
                                                        data={data}
                                                        actions={[
                                                            {
                                                                icon: <EditIcon />, title: "Edit",
                                                                onClick: (id, object, index) => {
                                                                    window.open("/"+objectType+"/" + object.objectid, {
                                                                        context: "search_result",
                                                                        search: {
                                                                            index
                                                                        }
                                                                    });
                                                                }
                                                            }
                                                        ]}
                                                    />
                                                </TabsPanels>
                                            )
                                        },
                                        {
                                            label: "Multiples",
                                            panel: (
                                                <TabsPanels>
                                                    <Title>
                                                        {dataMultiple.length} {t('results')}
                                                    </Title>
                                                    <ListMultipleLayout
                                                        data={dataMultiple}
                                                        actions={[
                                                            {
                                                                icon: <EditIcon />, title: "Edit",
                                                                onClick: (id, object, index) => {
                                                                    window.open("/"+objectType+"/" + object.oeuvre_objectid, {
                                                                        context: "search_result",
                                                                        search: {
                                                                            index
                                                                        }
                                                                    });
                                                                }
                                                            }
                                                        ]}
                                                    />
                                                </TabsPanels>
                                            )
                                        },
                                    ]}
                                />

                            </TabsContainer>
                        </Container>
                    </Grid>
                </Grid>

            </React.Fragment>

        )
    }
    

    return (
        <React.Fragment>
            <Helmet title="Rechercher une oeuvre" />

                <div>
                    <Button
                        variant="contained" color="primary" style={{marginRight:'20px'}}
                        onClick={ _clean }
                    >{"RESET"}</Button>
                    <Button
                        variant="contained" color="primary" style={{marginRight:'50px'}}
                        onClick={_search}
                    >{t("search").toUpperCase()}</Button>

                <Title style={{verticalAlign:'middle'}}>
                    Rechercher une oeuvre
                </Title>
            </div>
            <Divider my={6} />

            <Grid container spacing={2} style={{ width: '100%' }}>
                <Grid item xs={12}>
                    <Container>
                        {!loading &&
                            <SearchPanel layout="search" data={(searchObject && searchObject.form) || {}} onChange={setSearchForm} />
                        }
                        {loading && <CircularProgress />}
                    </Container>
                </Grid>
            </Grid>

        </React.Fragment>

    )

    

}