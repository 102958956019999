import { apiCall, constructUrl, getResult, fetchApi as fetch } from './ApiCall';
import { getContactGenres, critereArrayFromString, critereStringFromArray } from 'Util/ContactCriteres';
import { getOeuvreById } from 'Api/artworks';


const constructContact = contact => ({
    ...contact,
    genre_contacts: (contact.genre_contacts || []).map(item => item.objectid),
    genres_array: critereArrayFromString(contact.genres_string, getContactGenres()),

})

const deduplicate = (oeuvres) => {

     var uniqueOeuvres = []

     if ( oeuvres !== null ) {
    
        var index = 0     
        oeuvres.forEach(oeuvre => {
            
            var found = false
            
            if ( index === 0 ) {
                uniqueOeuvres.push(oeuvre)
            }

            uniqueOeuvres.forEach(uniqueOeuvre => {

                if ( oeuvre.oeuvre_id === uniqueOeuvre.oeuvre_id ) {
                    found = true
                }

            })
            if ( found === false ) {
                uniqueOeuvres.push(oeuvre)
            }

            index = index + 1
        })
    }
    return uniqueOeuvres
}

export const getAllContacts = async ({ cursor, limit } = {}) => {
    return await apiCall("getallcontacts")
}

export const getContactById = async ({ id }) => {
    const rs = await apiCall("getcontact", "?contact_id=" + id)
    if (rs.contact && rs.contact.length > 0) {
        var newContact = constructContact(rs.contact[0])
        var oeuvres_contact = []
        const allOeuvres = newContact.histo_oeuvre
        const uniqueOeuvres = deduplicate(allOeuvres)
        var count = 0

        const promises = uniqueOeuvres.map(async (oeuvre, i) => {

            let dataOeuvre = await getOeuvreById({id: oeuvre.oeuvre_id})
            let newOeuvre = dataOeuvre.oeuvre

            oeuvres_contact.push(newOeuvre)
            count = count + 1
         })

        await Promise.all(promises)
        newContact["oeuvres_contact"] = oeuvres_contact
        return { contact: newContact }
    }

    return null;
}

export const deleteContact = async ({ contact }) => {

    await apiCall("deletecontact", "?contact_id=" + contact.objectid)
    
}

export const createContact = async ({ data }) => {
    const url = constructUrl("createcontact");
    data['genres_string'] = critereStringFromArray(data['genres_array'], getContactGenres())

    var formdata = new FormData();
    formdata.append("contact", JSON.stringify({contactData: data}));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'createcontact');
}

export const updateContact = async ({ id, data }) => {
    const url = constructUrl("updatecontact");
    data['genres_string'] = critereStringFromArray(data['genres_array'], getContactGenres())
    data['histo_oeuvre'] = null
    data['oeuvres_contact'] = null
    
    var formdata = new FormData();
    formdata.append("contact_id", id);
    formdata.append("contact", JSON.stringify({contactData: data}));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'updatecontact');
}

