import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { createBiblio } from 'Api'
import BiblioLayout from './index';
import { CircularProgress } from '@material-ui/core'
import { checkTimeout } from 'Api/ApiCall'

export default () => {

    const history = useHistory();
    const [ loading, setLoading ] = useState(true);

    const loadData = async () => {
        checkTimeout()

        setLoading(false);
    }

    useEffect(() => {
        loadData();
    })

    let content = null;
    if (loading) {
        content = <CircularProgress />
    } else {
        content = (
            <BiblioLayout
                layout="create"
                saveData={async data => {
                    const result = await createBiblio({ data });
                    return result;
                }}
                onSaved={biblio => {
                    history.push("/biblio/" + biblio.objectId);
                }}
           />
        )
    }

    return (
        <>
           {content}
       </>
   )
}