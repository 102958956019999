import React from 'react';
import DataTable from 'components/DataTableWithHeader'
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
} from '@material-ui/icons'

import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'

import Image from 'components/Img'
import Photos from 'data/Photos';

import { useCurrentPermissionProfile } from 'redux/hooks';
import { deletePhoto } from 'Api'
import { sortAlpha, sortNumbers, sortPhotos } from 'Util/Sorting'

const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = Photos, actions, onChange, onClickEdit, headerActions }) => {

    const { t } = useTranslation();
    const permissionProfile = useCurrentPermissionProfile();
    const canDelete = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === 'adriana' || permissionProfile === 'avila' || permissionProfile === null;
    const canEdit = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === 'adriana' || permissionProfile === 'avila' || permissionProfile === 'regisseur' || permissionProfile === null;

    
    async function deletePhotoAsync(photo) {

        await deletePhoto({ photo });
        onChange(data.filter(e => e.objectid !== photo.objectid))
    }

    function sorting ( category, order ) {

        data.sort(function (a, b) {

            if ( category === "annee" ) {

                return sortNumbers(a[category], b[category], order)

            } else if ( (category === "photographe")) {

                return sortAlpha(a[category], b[category], order)
           
            } else if ( (category === "archive")) {

                return sortPhotos(a[category], b[category], order)
            }
            return -1
        });
    }
    
    return (
        <Paper elevation={3}>
            {headerActions && (
                <div>
                    {headerActions}
                </div>
            )}
            <DataTable
                data={data}
                defaultOrderBy={"archive"}
                onRequestSort={sorting}
                config={{
                    onClickRow: photo => {
                        window.open("/photo/" + photo.objectid)
                    },
                    cells: [
                        { title: t('archive'), key: 'archive', width:'120px', bold:true },
                        { title: t('photo'), key: 'filename', width:'80px', sort:false },
                        { title: t('annee'), key: 'annee', width:'80px' },
                        { title: t('photographe'), key: 'photographe', width:'300px' },
                        { title: t('copyright'), key: 'copyright', width:'300px', sort:false },
                        { title: t('localisation-exposition'), key: 'localisation', sort:false },
                    ],
                    actions: actions || [
                        ...(canEdit ? [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, photo) => {
                                window.open("/photo/" + photo.objectid)
                            }
                        }, ] : []),
                        ...(canDelete ? [
                        {   
                            icon: <DeleteIcon />, title: "Delete",
                            onClick: (id, photo) => {  
                                if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                    deletePhotoAsync(photo)
                                }
                            }
                        } ] : [])
                    ]
                }}
                getCell={(item, attr_name) => {

                    if (attr_name === "filename") {
                        return (
                            <Image
                                imageWidth={60}
                            style={{justifyContent: 'flex-start'}}
                                alt="" filename={item.filename}
                            />
                        )
                    }

                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>


    )
}