import { SET_COLORS as SET_TYPE } from 'redux/constants';
import { getAllColors as getAll } from 'Api'

const setData = data => ({ type: SET_TYPE, data })

export const loadColors = () => async (dispatch, getState) => {
    const data = await getAll();

    if (data.couleurs) {
        return dispatch(setData(data.couleurs));
    }
}
