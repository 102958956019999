import { combineReducers } from 'redux';

import themeReducer from './themeReducers';
import colors from './colors';
import materiaux from './materiaux';
import familles from './familles';
import elements from './elements';
import type_expos from './type_expos';
import criteres from './criteres';
import genre_contacts from './genre_contacts';
import search from './search';
import auth from './auth';
import list from './list';

export default combineReducers({
	themeReducer,
	colors,
	materiaux,
	familles,
	elements,
	type_expos,
	criteres,
	genre_contacts,
	search,
	auth,
	list
});
