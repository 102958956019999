export const getPoundsFromKg = (kgValue) => 
{
	return Math.round((kgValue/2.20)*100)/100
}

export const dictionaryContainsItemWithKey = (dictionary, key) => {

}

export const displayBigNumber = number => {

    return number
}

export const cleanNumber = numberString => {

	if ( typeof(numberString) == "number" ) {
		return numberString
	} else if ( typeof(numberString) == "string" ) {
		if ( numberString ) {

			const cleanNumberString = numberString.replace ( /[^0-9]/g, '' );
			if ( cleanNumberString === "" ) {
				return 0
			}
	   		return parseInt(cleanNumberString)
	   	}
	}
	return 0
}


export const cleanBigNumber = numberString => {

	if ( typeof(numberString) == "number" ) {
		return numberString
	} else if ( typeof(numberString) == "string" ) {
		if ( numberString ) {

	    	var cleanNumber = numberString.replaceAll(',', '');
	    	cleanNumber = cleanNumber.replaceAll('.', '');

	   		return cleanNumber
	   	}
	}
	return null
}


export const parseBigNumber = numberString => {

    return numberString
}


export const getDimensions = oeuvre => {
    var dimensions = ""

	if ( oeuvre["largeur"] ) {
		dimensions = oeuvre["largeur"] + " x "
	} else {
		dimensions = "-- x "
	}
	if ( oeuvre["profondeur"] ) {
		dimensions = dimensions + oeuvre["profondeur"] + " x "
	} else {
		dimensions = dimensions + "-- x "
	}
	if ( oeuvre["hauteur"] ) {
		dimensions = dimensions + oeuvre["hauteur"]
	} else {
		dimensions = dimensions + "--"
	}
	return dimensions
}


export const trouverDifferences = (str1, str2) => {
    const maxLength = Math.max(str1.length, str2.length);
    let differences = [];
  
    for (let i = 0; i < maxLength; i++) {
      if (str1[i] !== str2[i]) {
        differences.push(`Position ${i}: "${str1[i] || ""}" vs "${str2[i] || ""}"`);
      }
    }
  
    return differences;
  }