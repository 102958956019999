export const SET_THEME = 'SET_THEME';

export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const CLEAR_AUTH = 'CLEAR_AUTH';

export const SET_COLORS = 'SET_COLORS';
export const SET_MATERIAUX = 'SET_MATERIAUX';
export const SET_ELEMENTS = 'SET_ELEMENTS';
export const SET_FAMILLE = 'SET_FAMILLE'; // Je demande la soeur

export const SET_TYPE_EXPOS = 'SET_TYPE_EXPOS';

export const SET_CRITERES = 'SET_CRITERES';

export const SET_GENRE_CONTACTS = 'SET_GENRE_CONTACTS';

export const SET_SEARCH_FORM = 'SET_SEARCH_FORM';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';

export const SET_LIST_RESULTS = 'SET_LIST_RESULTS';
