import React from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import { useSearch } from 'redux/hooks';

import {
    Button,
    Divider
} from '@material-ui/core'

import { FlexRowSpaceBetween } from 'layouts/Helpers/flexbox';


export default ({
    search_type = 'artwork',
    constructPath = artwork => ("/artwork/" + artwork.objectid),
    searchPath = '/artwork/search'
}) => {

    const [ searchObject ] = useSearch(search_type);
    const history = useHistory();
    const location = useLocation();
    const search_context = (location.state && location.state.search) || null;

    if (!searchObject.results) {
        return null;
    }

    const goToIndex = index => {
        if (!searchObject.results[index]) {
            return;
        }
        history.push(constructPath(searchObject.results[index]), {
            context: "search_result",
            search: {
                index
            }
        });
    }

    console.log("SearchNav search_context", search_context)

    return (
        <>
            <FlexRowSpaceBetween style={{marginBottom: '10px'}}>
                <Button
                    color="primary" variant="contained"
                    onClick={() => history.push(searchPath)}
                >Retour aux résultats</Button>
                <div>
                    <Button
                        color="primary" variant="contained" style={{marginRight:'20px'}}
                        onClick={() => goToIndex(search_context.index - 1)}
                    >{"< "}Previous</Button>
                    <Button
                        color="primary" variant="contained"
                        onClick={() => goToIndex(search_context.index + 1)}
                    >Next{" >"}</Button>
                </div>
            </FlexRowSpaceBetween>

            <Divider style={{marginBottom: '10px', marginTop: '10px'}}/>
        </>
    )
}