

export const sortDate = (dateA, dateB, order) => {

    if ( (isNaN(Date.parse(dateA)) === false) && (isNaN(Date.parse(dateB)) === false) ) {

        if ( dateA > dateB ) {
            return -1
        }
        return 1
    } else if (isNaN(Date.parse(dateA)) === true ) {
        return -1
    } else if (isNaN(Date.parse(dateB)) === true ) {
        return 1
    }
    return 1
}

export const sortDateBis = (dateA, dateB, order) => {

    if ( (isNaN(Date.parse(dateA)) === false) && (isNaN(Date.parse(dateB)) === false) ) {

        if ( dateA > dateB ) {
            return -1
        }
        return 1
    } else if (isNaN(Date.parse(dateA)) === true ) {
        return 1
    } else if (isNaN(Date.parse(dateB)) === true ) {
        return -1
    }
    return 1
}

export const sortNumbers = (a, b, asc) => {

    if ( asc === true ) {
        if ( a > b ) {
            return 1
        }
        return -1
    } else {
        if ( a > b ) {
            return -1
        }
        return 1
    }
}


export const sortAlpha = (a, b, asc) => {

    if ( (a !== null) && (b != null) ) {
        if ( a === "" ) {
            if ( asc === true ) {
                return -1
            }
            return 1
        }

        if ( b === "" ) {
            if ( asc === true ) {
                return 1
            }
            return -1
        }

        if (a === b) {
            return 1
        } 


        if ( asc === true ) {
            return a.toLowerCase().localeCompare(b.toLowerCase()) === 1 ? 1 : -1 ;
        } else {
            return b.toLowerCase().localeCompare(a.toLowerCase()) === 1 ? 1 : -1 ;
        }
    } else {

        if ( a ) {
            if ( asc === true ) {
                return 1
            } else {
                return -1
           }
        }
        if  ( b ) {
            if ( asc === true ) {
                return -1
            } else {
                return 1
           }
        }
    }
}


export const sortArchive = (a, b, asc) => {

    if ( (a !== null) && (b !== null) ) {

        if ( a === "" ) {
            if ( asc === true ) {
                return -1
            }
            return 1
        }

        if ( b === "" ) {
            if ( asc === true ) {
                return 1
            }
            return -1
        }

        if (a === b) {
            return 1
        } 

        var firstPartA = a
        var firstPartB = b

        var secondPartA = 0
        var secondPartB = 0

        if ( firstPartA.includes("-") ) {
            const splitA = a.split('-')
            firstPartA = splitA[0].toLowerCase()
            secondPartA = parseInt(splitA[1])

        }

        if ( firstPartB.includes("-") ) {
            const splitB = b.split('-')
            firstPartB = splitB[0].toLowerCase()
            secondPartB = parseInt(splitB[1])

        }
        
        var regexA = /[^a-zA-Z]/g;
        var regexN = /[^0-9]/g;

        var aA = firstPartA.replace(regexA, "");
        var bA = firstPartB.replace(regexA, "");
    
        if (aA === bA) {
    
            var aN = parseInt(firstPartA.replace(regexN, ""), 10);
            var bN = parseInt(firstPartB.replace(regexN, ""), 10);

            
            if (aN === bN) {
                if ( asc === true ) {
                    return secondPartA === secondPartB ? 1 : secondPartA > secondPartB ? 1 : -1;
                } else {
                    return secondPartA === secondPartB ? -1 : secondPartA < secondPartB ? 1 : -1;            
                }
            } else {
                if ( asc === true ) {
                    return aN === bN ? 1 : aN > bN ? 1 : -1;
                } else {
                    return aN === bN ? -1 : aN < bN ? 1 : -1;            
                }
            }
        } 
 
        if ( asc === true ) {
            return aA.localeCompare(bA) === 1 ? 1 : -1 ;
        } else {
            return bA.localeCompare(aA) === 1 ? 1 : -1 ;
        }
    } else if ( a === null ) {
        if ( asc === true ) {
            return -1
        }
        return 1
    } else if ( b === null ) {
        if ( asc === true ) {
            return 1
        }
        return -1
    }
    return 1
}

export const sortArchiveMedia = (a, b, asc) => {

    if ( (a !== null) && (b !== null) ) {

        if ( a === "" ) {
            if ( asc === true ) {
                return -1
            }
            return 1
        }

        if ( b === "" ) {
            if ( asc === true ) {
                return 1
            }
            return -1
        }

        if (a === b) {
            return 1
        } 

        var firstPartA = a
        var firstPartB = b
        var secondPartA = 0
        var secondPartB = 0

        if ( firstPartA.includes("-") ) {
            const splitA = a.split('-')
            firstPartA = splitA[0].toLowerCase()
            secondPartA = parseInt(splitA[1])
        }

        if ( firstPartB.includes("-") ) {
            const splitB = b.split('-')
            firstPartB = splitB[0].toLowerCase()
            secondPartB = parseInt(splitB[1])
        }
        
    
        if ( firstPartA === firstPartB ) {

            if ( asc === true ) {
                return secondPartA === secondPartB ? 1 : secondPartA > secondPartB ? 1 : -1;
            } else {
                return secondPartA === secondPartB ? -1 : secondPartA < secondPartB ? 1 : -1;            
            }
        } 
 
        if ( asc === true ) {
            return firstPartA.localeCompare(firstPartB) === 1 ? 1 : -1 ;
        } else {
            return firstPartB.localeCompare(firstPartA) === 1 ? 1 : -1 ;
        }
    } else if ( a === null ) {
        if ( asc === true ) {
            return -1
        }
        return 1
    } else if ( b === null ) {
        if ( asc === true ) {
            return 1
        }
        return -1
    }
    return 1
}


export const sortArchiveBiblio = (a, b, asc) => {

    if ( (a !== null) && (b !== null) ) {

        if ( a === "" ) {
            if ( asc === true ) {
                return -1
            }
            return 1
        }

        if ( b === "" ) {
            if ( asc === true ) {
                return 1
            }
            return -1
        }

        if ( a === b ) {
            return 1
        }

        const numberA = (a.match(new RegExp("-", 'gi')) || []).length
        const numberB = (b.match(new RegExp("-", 'gi')) || []).length
        var firstPartA = a
        var firstPartB = b
        var secondPartA = 0
        var secondPartB = 0
        var splitA = ""
        var splitB = ""

        if ( numberA === 2 ) {
            splitA = a.split('-')
            firstPartA = splitA[0].toLowerCase() + splitA[1].toLowerCase()
            secondPartA = parseInt(splitA[2].replace("GF", ""))

        } else if ( numberA === 1 ) {
            splitA = a.split('-')
            firstPartA = splitA[0].toLowerCase()
            secondPartA = parseInt(splitA[1].replace("GF", ""))
        }
        
        if ( numberB === 2 ) {
            splitB = b.split('-')
            firstPartB = splitB[0].toLowerCase() + splitB[1].toLowerCase()
            secondPartB = parseInt(splitB[2].replace("GF", ""))
        } else if ( numberB === 1 ) {
            splitB = b.split('-')
            firstPartB = splitB[0].toLowerCase()
            secondPartB = parseInt(splitB[1].replace("GF", ""))
        }
    
        if ( firstPartA === firstPartB ) {

            if ( asc === true ) {
                return secondPartA === secondPartB ? 1 : secondPartA > secondPartB ? 1 : -1;
            } else {
                return secondPartA === secondPartB ? -1 : secondPartA < secondPartB ? 1 : -1;            
            }
        } 
 
        if ( asc === true ) {
            return firstPartA.localeCompare(firstPartB) === 1 ? 1 : -1 ;
        } else {
            return firstPartB.localeCompare(firstPartA) === 1 ? 1 : -1 ;
        }
     /*   var firstPartA = a.replace("-", "").replace("GF", "");
        var firstPartB = b.replace("-", "").replace("GF", "");
        
        var regexA = /[^a-zA-Z]/g;
        var regexN = /[^0-9]/g;

        var aA = firstPartA.replace(regexA, "");
        var bA = firstPartB.replace(regexA, "");
    */


      /*  if (aA === bA) {
            if ( firstPartA === firstPartB ) {

            var aN = parseInt(firstPartA.replace(regexN, ""), 10);
            var bN = parseInt(firstPartB.replace(regexN, ""), 10);

    
            if ( asc === true ) {
                return aN === bN ? 1 : aN > bN ? 1 : -1;
            } else {
                return aN === bN ? -1 : aN < bN ? 1 : -1;            
            }
        } 
 
        if ( asc === true ) {
            return aA.localeCompare(bA) === 1 ? 1 : -1 ;
        } else {
            return bA.localeCompare(aA) === 1 ? 1 : -1 ;
        }*/
    } else if ( a === null ) {
        if ( asc === true ) {
            return -1
        }
        return 1
    } else if ( b === null ) {
        if ( asc === true ) {
            return 1
        }
        return -1
    }
    return 1
}

export const sortAlphaNum = (a, b, asc) => {

    if ( (a !== null) && (b !== null) ) {

        if ( a === "" ) {
            if ( asc === true ) {
                return -1
            }
            return 1
        }

        if ( b === "" ) {
            if ( asc === true ) {
                return 1
            }
            return -1
        }

        if (a === b) {
            return 1
        } 

        var firstPartA = a
        var firstPartB = b

        if ( firstPartA.includes("-") ) {
            const splitA = a.split('-')
            firstPartA = splitA[0].toLowerCase()
        }

        if ( firstPartB.includes("-") ) {
            const splitB = b.split('-')
            firstPartB = splitB[0].toLowerCase()
        }
        
        var regexA = /[^a-zA-Z]/g;
        var regexN = /[^0-9]/g;

        var aA = firstPartA.replace(regexA, "");
        var bA = firstPartB.replace(regexA, "");
    
        if (aA === bA) {
    
            var aN = parseInt(firstPartA.replace(regexN, ""), 10);
            var bN = parseInt(firstPartB.replace(regexN, ""), 10);
    
            if ( asc === true ) {
                return aN === bN ? 1 : aN > bN ? 1 : -1;
            } else {
                return aN === bN ? -1 : aN < bN ? 1 : -1;            
            }
        } 
 
        if ( asc === true ) {
            return aA.localeCompare(bA) === 1 ? 1 : -1 ;
        } else {
            return bA.localeCompare(aA) === 1 ? 1 : -1 ;
        }
    } else if ( a === null ) {
        if ( asc === true ) {
            return -1
        }
        return 1
    } else if ( b === null ) {
        if ( asc === true ) {
            return 1
        }
        return -1
    }
    return 1

}



export const sortPhotos = (a, b, asc) => {

    if ( (a !== null) && (b !== null) ) {

        var regexN = /[^0-9]/g;
        var stringA = a.replace(regexN, "");
        var stringB = b.replace(regexN, "");

        const numberA = parseInt(stringA, 10)
        const numberB = parseInt(stringB, 10)
    
        if ( isNaN(numberA) === true  ) {
          //  console.log("numberA not a number: ", stringA)
            if ( asc === true ) {
                return -1
            }
            return 1
        }
        if ( isNaN(numberB) === true  ) {
         //   console.log("numberB not a number: ", stringB)
            if ( asc === true ) {
                return 1
            }
            return -1
        }        
        if ( asc === true ) {
            if ( numberA > numberB ) {
                return 1
            }
            return -1
        } else {
            if ( numberA > numberB ) {
                return -1
            }
            return 1
        }
    
    } else if ( a === null ) {
        console.log("a is null")

        if ( asc === true ) {
            return -1
        }
        return 1
        
    } else if ( b === null ) {
        console.log("b is null")

        if ( asc === true ) {
            return 1
        }
        return -1
    } else {

        console.log("string: " + a )
        console.log("string: " + b )
    
    }
    return 1

}

