import * as types from '../constants';

export default function reducer(state = {}, actions) {
    
   // console.log("search reducer : ", actions)

    if (!actions.search_type) {
        return state;
    }

    switch (actions.type) {

        case types.SET_SEARCH_FORM:
            return {
                ...state,
                [actions.search_type]: {
                    ...(state[actions.search_type] || {}),
                    form: actions.form
                }
            }

        case types.SET_SEARCH_RESULTS:
            return {
                ...state,
                [actions.search_type]: {
                    ...(state[actions.search_type] || {}),
                    results: actions.results
                }
            }

        default:
            return state
    }
}
