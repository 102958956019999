import React, { useState } from 'react';
import DataTable from 'components/DataTable'
import EnchereModal from 'pages/Avila/Modals/Enchere'
import EstimationModal from 'pages/Avila/Modals/Estimation'
import PrixModal from 'pages/Avila/Modals/Prix'

import { useTranslation } from 'react-i18next'
import Moment from 'moment';

import { isSelectedMultiples  } from 'Util/OeuvreMultiple';
import { filterEncheres } from 'Util/OeuvreEnchere';
import { updateOeuvre } from 'Api/artworks'
import { sortDate } from 'Util/Sorting'

import {
    Edit as EditIcon,
    Delete as DeleteIcon

} from '@material-ui/icons'

import {
    Grid, Button
} from '@material-ui/core';

import styled from 'styled-components';

const GridContainerStyled = styled(Grid)`
    width: 100%;
`
const GridContainer = props => (
    <div style={{padding: "15px"}}>
        <GridContainerStyled container spacing={6} {...props} />
    </div>
)


export default ({ data, selectedMultiples, isMultiple, reloadData = () => {}, actions, onChange = () => {}, onClickEdit }) => {

    const [ editEnchere, setEditEnchere ] = useState(null);
    const [ editEstimation, setEditEstimation ] = useState(null);
    const [ editPrix, setEditPrix ] = useState(null);
    const { t } = useTranslation();

    const setEditInfo = data => {

        if (data.type === "enchere") {
            setEditEnchere(data)
        } else if (data.type === "estimation") {
            setEditEstimation(data)
        } else {
            setEditPrix(data)
        }

    }
    
    if ( data.encheres ) {

        data.encheres.sort(function (a, b) {
            return sortDate(a["date_enchere"], b["date_enchere"], false)
        });
    
    }
    const isFiltered = isSelectedMultiples(selectedMultiples)

    var filteredEncheres = []

    if ( isFiltered === true ) {
        filteredEncheres = filterEncheres(data.encheres, selectedMultiples)
    } else {
        filteredEncheres = data.encheres
    }

    async function updateOeuvreAsync(data) {
        await updateOeuvre({ id: data.objectid, data });
        onChange(data)
    }

    return (

        <GridContainer>
            <Grid item xs={12}>

                {editEnchere &&
                    <EnchereModal
                        data={editEnchere}
                        oeuvre={data}
                        oeuvreid={data.objectid}
                        isMultiple={isMultiple}
                        onClose={() => {
                            setEditEnchere(false)
                        }}
                        onSave={() => /*updateOeuvreAsync(data)*/ reloadData()}
                    />
                }

                {editEstimation &&
                    <EstimationModal
                        data={editEstimation}
                         oeuvre={data}
                       oeuvreid={data.objectid}
                        isMultiple={isMultiple}
                        onClose={() => {
                            setEditEstimation(false)
                        }}
                        onSave={reloadData}
                    />
                }


                {editPrix &&
                    <PrixModal
                        data={editPrix}
                        oeuvre={data}
                        oeuvreid={data.objectid}
                        isMultiple={isMultiple}
                        onClose={() => {
                            setEditPrix(false)
                        }}
                        onSave={reloadData}
                    />
                }

                <DataTable
                    data={filteredEncheres}
                    headerActions={[
                        <Button style={{marginRight:'20px'}} key="add_enchrre" onClick={() => setEditEnchere({})} variant="contained">{t("add_enchere")}</Button>,
                        <Button style={{marginRight:'20px'}} key="add_estimation" onClick={() => setEditEstimation({})} variant="contained">{t("add_estimation")}</Button>,
                        <Button key="add_prix" onClick={() => setEditPrix({})} variant="contained">{t("add_prix")}</Button>
                    ]}
                    config={{
                        cells: [
                            { title: t('date'), key: 'date_enchere' },
                            { title: t('genre'), key: 'type' },
                            ...(isMultiple ? [
                                { title: t('exemplaire'), key: 'multiple' },
                            ] : [] ),
                            { title: t('interlocuteur'), key: 'source' },
                            { title: t('estimation'), key: 'estimation' },
                            { title: t('prix'), key: 'prix_de_vente' },
                            { title: t('prix_au_marteau'), key: 'prix_marteau' },
                            { title: t('ville'), key: 'ville' },

                            { title: t('remarques'), key: 'remarques' },
                        ],
                        actions: actions || [
                            { icon: <EditIcon />, title: "Edit", onClick: (id, enchere) => setEditInfo(enchere)},
                            { icon: <DeleteIcon />, title: "Delete", onClick: (id, enchere) => {  
                                if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                    updateOeuvreAsync({
                                        ...data,
                                        encheres: data.encheres.filter(e => e.objectid !== enchere.objectid)
                                    })
                                }
                            }}
                        ]
                    }}
                    getCell={(item, attr_name) => {

                        if (attr_name === 'date_enchere') {
                            return Moment(item['date_enchere']).format('DD-MM-YYYY')
                        }
                        
                        if ( attr_name === "estimation" ) {
                            var estimation = ""

                            if ( item["estimation_min"] && item["estimation_max"] ) {
                               estimation = Intl.NumberFormat('fr-FR').format(item["estimation_min"]) + "-" + Intl.NumberFormat('fr-FR').format(item["estimation_max"])
                            } else if ( item["estimation_min"] && !item["estimation_max"] ) {
                               estimation = Intl.NumberFormat('fr-FR').format(item["estimation_min"])
                            } else if ( !item["estimation_min"] && item["estimation_max"] ) {
                               estimation = Intl.NumberFormat('fr-FR').format(item["estimation_max"])
                            }

                             if ( item["devise_estimation"] === "Euros" ) {
                                return "€" + estimation
                            } else if ( item["devise_estimation"] === "Dollars" ) {
                                return "$" + estimation
                            } else {
                                return estimation                                
                            }
                        }

                        if ( attr_name === "prix_de_vente" ) {

                            var prix = ""

                            if ( item["type"] === "enchere" ) {
                                if ( item["prix_de_vente"] ) {
                                    prix = Intl.NumberFormat('fr-FR').format(item["prix_de_vente"])
                                }
                            } else if ( item["type"] === "prix" ) {
                                 prix = Intl.NumberFormat('fr-FR').format(item["prix_de_vente"])
                            }

                            if ( prix === "" ) {
                                return  prix
                            } else {
                                if ( item["devise_prix_de_vente"] === "Euros" ) {
                                    return "€" + prix
                                } else if ( item["devise_prix_de_vente"] === "Dollars" ) {
                                    return "$" + prix                                    
                                }
                            }
                            return  prix

                        } else if ( attr_name === "prix_marteau" ) {

                            prix = ""
                            
                            if ( item["prix_marteau"] ) {
                                prix = Intl.NumberFormat('fr-FR').format(item["prix_marteau"])
                             }

                            if ( prix === "" ) {
                                return  prix
                            } else {
                                if ( item["devise_prix_au_marteau"] === "Euros" ) {
                                    return "€" + prix
                                } else if ( item["devise_prix_au_marteau"] === "Dollars" ) {
                                    return "$" + prix                                    
                                }
                            }
                            return  prix
                        } 
                        return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                    }}
                />

            </Grid>

        </GridContainer>
    )

}