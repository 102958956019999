// @flow
import React from 'react';

import { createTransportUpdateOeuvre, updateTransportUpdateOeuvre } from 'Api';
import TransportForm from 'pages/Avila/Transport/Form'

import FormModal from './FormModal'
import { useTranslation } from 'react-i18next'

type Props = {
    oeuvreid: string,
    onClose: any => any,
    actions: Array<any>,
    data: any,
    onSave: any => any
}
export default ({
    onClose,
    actions = [],
    data,
    oeuvre,
    oeuvreid,
    onSave = () => {}
}: Props) => {

    const { t } = useTranslation();

    return (

        <FormModal
            title={t("caisse_de_transport")}
            data={data}
            onClose={onClose}
            FormComponent={TransportForm}
            onSave={onSave}
            updateMethod={({ data }) => updateTransportUpdateOeuvre({ oeuvreid, data, oeuvre })}
            createMethod={({ data }) => createTransportUpdateOeuvre({ oeuvre, data })}
        />
    )
}