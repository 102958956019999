
import { getYear} from './Dates';
import { country} from './Listes';

export const displayOeuvreLastLocalisation = (data) => {

    var lastLocalisation = calcLastLocalisation(data.localisations)
    var display = ""

    if ( lastLocalisation ) {
        display = lastLocalisation.localisation

        if ( lastLocalisation.ville ) {
            display = display + ", " + lastLocalisation.ville
        }
        if ( lastLocalisation.pays ) {
            display = display + ", " + country(lastLocalisation.pays)
        }
        if ( lastLocalisation.date_localisation ) {
            display = display + " - " + getYear(lastLocalisation.date_localisation)
        }
    } else {
        display = "Localisation inconnue"
    }

    return display
}



export const calcLastLocalisation = (localisations) => {

    var lastLocalisation = null

    if ( localisations && localisations.length > 0 ) {

        localisations.sort(function (a, b) {
            if ( a && b ) {
                if ( a.date_localisation && b.date_localisation ) {
                    return a.date_localisation > b.date_localisation ? -1 : 1;
                } else if ( a.date_localisation ) {
                    return -1
                } else if ( b.date_localisation ) {
                    return 1
                }
            }
            return -1
        });
        lastLocalisation = localisations[0]
    }
    return lastLocalisation
}


export const filterLocalisations = (localisations, selectedMultiples) => {

     var filteredLocalisations = []
     const keys = Object.keys(selectedMultiples)

     keys.forEach(multiple => {

        if ( selectedMultiples[multiple] === true ) {

            localisations.forEach(localisation => {
                if ( localisation.multiple === multiple ) {
                    filteredLocalisations.push(localisation)
                }
            })
        }
     })

     return filteredLocalisations
}

