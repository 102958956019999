import React from 'react';
import DataTable from 'components/DataTableWithHeader'
import Image from 'components/Img'
import TYPES from 'Assets/json/TypesOeuvre.json'
import { Button } from '@material-ui/core';

import Moment from 'moment';
import { useTranslation } from 'react-i18next'
import { saveAs } from 'file-saver'
import { getDimensions } from 'Util/Divers'
import { getPhotoImagePath, insertImageWidth, getPhotoImageExtension, addImageToWB } from 'Util/Export'
import { sortNumbers, sortAlpha } from 'Util/Sorting'
import { photoItemsWithType } from 'Util/Oeuvre'

export default ({ data, actions, onClickEdit }) => {
    
    const { t } = useTranslation();
    
    data.sort(function (a, b) {
        if ( a.archive && b.archive ) {
            return (parseInt(a.archive) > parseInt (b.archive)) ? 1 : -1;
        } else {
            return -1
        }
    });

/*
    async function saveAsExcel() {
        const ExcelJS = require('exceljs');
        const wb = new ExcelJS.Workbook()
        const ws = wb.addWorksheet()
        
        ws.columns = [
            { header: 'Archive', key: 'archive', width: 8 },
            { header: 'Photo', key: 'filename', width: 30 },
            { header: 'Titre', key: 'titre', width: 80 },
            { header: 'Dernière localisation', key: 'derniere_localisation', width: 30 },
            { header: 'Date', key: 'date', width: 10 },
            { header: 'Dimensions', key: 'Dimensions', width: 15 },
        ];


        data.forEach(oeuvre => {
            console.log("oeuvre: ", oeuvre)

            var dimensions = getDimensions(oeuvre)
            var filename = ""

            const row = ws.addRow([oeuvre.archive, filename, oeuvre.titre, oeuvre.derniere_localisation, oeuvre.annee_creation, dimensions])
            row.font = { bold: false }
            row.height = 100;
        })

        await insertImages(wb, ws, data, 1)

        const buf = await wb.xlsx.writeBuffer()
        const filename = "Contact.xlsx"

        saveAs(new Blob([buf]), filename)
    }*/


        async function saveAsExcel() {
            const ExcelJS = require('exceljs');
            const wb = new ExcelJS.Workbook();
            const ws = wb.addWorksheet();
        
            // Définir les colonnes de la feuille Excel
            ws.columns = [
                { header: 'Archive', key: 'archive', width: 8 },
                { header: 'Photo', key: 'filename', width: 30 },
                { header: 'Titre', key: 'titre', width: 80 },
                { header: 'Dernière localisation', key: 'derniere_localisation', width: 30 },
                { header: 'Date', key: 'date', width: 10 },
                { header: 'Dimensions', key: 'Dimensions', width: 15 },
            ];
        
            // Traiter chaque œuvre une par une
            for (let i = 0; i < data.length; i++) {
                const oeuvre = data[i];
                console.log("oeuvre: ", oeuvre.archive)
                var dimensions = getDimensions(oeuvre);
                var filename = "";
        
                // Ajouter une ligne pour l'œuvre dans la feuille Excel
                const row = ws.addRow([oeuvre.archive, filename, oeuvre.titre, oeuvre.derniere_localisation, oeuvre.annee_creation, dimensions])
                row.font = { bold: false }
                row.height = 100;

                // Traiter l'image pour l'œuvre en cours
                let imagePath = getPhotoImagePath(oeuvre)
                const extension = getPhotoImageExtension(oeuvre);
        
                try {
                    const ret = await fetch(imagePath)

                    if ( ret ) {
                        const bufferData = await ret.arrayBuffer()
            
                        if ( bufferData ) {
                            
                            const imageId = addImageToWB(wb, bufferData, extension)
                            if (imageId) {
                                const width = await insertImageWidth(imagePath);
                                ws.addImage(imageId, { tl: { col: 1, row: i + 1 }, ext: { width: width, height: 120 } });
                            } else {
                                console.log("can't addImage for ", oeuvre.titre);
                            }
                            
                          /*  const imageId = wb.addImage({ buffer: Buffer.from(bufferData), extension: extension })
            
                 //   const imageId = wb.addImage({filename: imagePath, extension: extension});
                            if (imageId) {
                                const width = await insertImageWidth(imagePath);

                                ws.addImage(imageId, { tl: { col: 1, row: i + 1 }, ext: { width: width, height: 120 } });
                            } else {
                                console.log("can't addImage for ", oeuvre.titre);
                                const imageId = wb.addImage({ buffer: Buffer.from(bufferData), extension: extension })
                                if (imageId) {
                                    console.log("can addImage for ", oeuvre.titre)
                                } else {
                                    console.log("can't addImage for ", oeuvre.titre);

                                }
                            }*/
        
                        } else {
                            console.log("can't get image buffer for ", oeuvre.titre);
                        }
                    } else {
                        console.log("can't fetch image for ", oeuvre.titre);
                    }
                } catch (error) {
                    console.log("Error fetching image for ", oeuvre.titre, ":", error);
                }
            }
        
            const buf = await wb.xlsx.writeBuffer()
            saveAs(new Blob([buf]), "Contact.xlsx")
        }
        
    async function saveListeAssurance() {
        const ExcelJS = require('exceljs');
        const wb = new ExcelJS.Workbook()
        const ws = wb.addWorksheet()
        
        ws.columns = [
            { header: 'Titre', key: 'titre', width: 40 },
            { header: 'Année', key: 'annee', width: 10 },
            { header: 'Hauteur', key: 'hauteur', width: 10 },
            { header: 'Largeur', key: 'largeur', width: 10 },
            { header: 'Profondeur', key: 'profondeur', width: 10 },
            { header: 'Localisation', key: 'localisation', width: 50 },
            { header: 'Valeur d\'assurance', key: 'assurance', width: 15 },
        ];
 

        data.forEach(oeuvre => {

            var assurance = ""

            if ( oeuvre.assurances && oeuvre.assurances.length > 0) {
                assurance = Intl.NumberFormat('us-US').format(oeuvre.assurances[0]["valeur"]) + " " +  oeuvre.assurances[0]["devise"]
                assurance = assurance.replaceAll(",", ".")
            }

           const row = ws.addRow([
                oeuvre.titre,
                oeuvre.annee_creation,
                oeuvre.hauteur,
                oeuvre.largeur,
                oeuvre.profondeur,
                oeuvre.derniere_localisation,
                assurance,
            ])
            row.bold = false
            row.height = 40;
        })

        const buf = await wb.xlsx.writeBuffer()
        const filename = "ContactListeAssurance.xlsx"

        saveAs(new Blob([buf]), filename)
    }
    
    function sorting ( category, order ) {

        data.sort(function (a, b) {

            if ( (category === "archive") || (category === "annee_creation") || (category === "type_oeuvre") ) {
                return sortNumbers(a[category], b[category], order)
            } 
            return sortAlpha(a[category], b[category], order)
        });
    }
    

    return (
        <>

        <DataTable
            data={data}
            defaultOrderBy={"archive"}
            onRequestSort={sorting}
            headerActions={[
                <Button style={{marginLeft:'10px'}} key="exportListe" onClick={saveAsExcel} variant="contained">Export</Button>,
                <Button style={{marginLeft:'10px'}} key="exportAssurance" onClick={saveListeAssurance} variant="contained">Liste Assurance</Button>
            ]} 
            config={{
                cells: [
                    { title: t('archive'), key: 'archive', width:'100px' },
                    { title: t('photo'), key: 'photo_items', width:'50px', sort:false },
                    { title: t('title'), key: 'titre', width:'400px' },
                    { title: t('annee_creation'), key: 'annee_creation', width:'80px' },
                    { title: t('type'), key: 'type_oeuvre', width:'100px' },
                    { title: t('date_acquisition'), key: 'date_historique', width:'80px', sort:false },
                    { title: t('transaction'), key: 'type', width:'100px', sort:false },
                ],
                onClickRow: oeuvre => {
                    window.open("/artwork/" + oeuvre.objectid)
                },

            }}
            getCell={(item, attr_name) => {

                if ( attr_name === "date_historique" ) {
                    if (item['date_historique']) {
                        return  Moment(item['date_historique']).format('YYYY')
                    }
                    return ""

                } else if ( attr_name === "type_oeuvre" ) {

                    const type = item["type_oeuvre"]-1

                    if ( TYPES[type] ) {
                        return TYPES[type].loc["fr"]
                    }
                    return "inconnu"

                } else if ( attr_name === "photo_items" ) {
                    var filename = ""
                    const photos = photoItemsWithType(item.photo_items, "1")
                    
                    if ( photos.length > 0 ) {
                        filename = photos[0].filename
                    }

                    return (
                        <Image
                            imageWidth={60}
                            style={{justifyContent: 'flex-start'}}
                            alt="" filename={filename}
                        />
                    )
                }
                return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
            }}
        />
        </>
    )

}