import React from 'react';
import styled from 'styled-components';
import JoditEditor from "../JoEdit";

import {
    Grid,
    TextField as MuiTextField,
    Checkbox,
} from '@material-ui/core';

import { RadioGroup, Radio, FormControlLabel, FormControl } from '@material-ui/core';

import Autocompletes from './Autocompletes'
import Selects from './Selects'
import TextFields from './TextFields'
import DatePicker from '../Datepicker';

//import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';


import DurationInputMask from 'react-duration-input-mask';
//import props from 'theme/props';

const TableCell = styled.div`
    padding:6px;
    border-bottom: none;
`

const Line = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const FormInputContainerInlineContainer = styled.div`
    width: 100%;
    height: 100%;
    margin-bottom: 10px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    table {
        height: 100%;
    }

    .inputContent {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        input, textarea {
            width: 100%;
        }
    }
    .label {
        margin-right: 6px;
    }
    &.first {
        > .label {
            margin-right: 0px;
            min-width: 130px;
        }
    }

    &.checkbox {
        justify-content: flex-start;
        align-items: center;
    }
`

const TextField = styled(MuiTextField)`
    width: 100%;
    margin-bottom: 10px !important;
`

const FormInputContainerInline = props => (
    <FormInputContainerInlineContainer>
        <Line>{props.children}</Line>
    </FormInputContainerInlineContainer>
)
/*
const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`

const  Quill = (value, onChange) => {

    return (
        <QuillWrapper>
            <ReactQuill theme="snow" value={value.value} onChange={value.onChange} placeholder="Type something.." />
        </QuillWrapper>
    );
}*/

        const configJoEditor = {
            readonly: false,
            toolbar: true,
            colorPickerDefaultTab: 'background',
            width: '800px',
            sizeLG: 800,
            sizeMD: 800,
            sizeSM: 800,
            buttons: [
                'bold',
                'underline',
                'italic',
                'brush',
            ],
            buttonsXS: [
                'bold',
                'underline',
                'italic',
                'brush',
            ],
            textIcons: false,
            extraButtons: [],
            removeButtons: [],
        }


        const configJoEditor_biblio = {
            readonly: false,
            toolbar: true,
            colorPickerDefaultTab: 'background',
            width: '400px',
            sizeLG: 400,
            sizeMD: 400,
            sizeSM: 400,
            buttons: [
                'bold',
                'underline',
                'italic',
                'brush',
            ],
            buttonsXS: [
                'bold',
                'underline',
                'italic',
                'brush',
            ],
            textIcons: false,
            extraButtons: [],
            removeButtons: [],
        }

export const FormItem = ({ item, value, onChange, isFirst = false, inline = false }) => {


    const FormInputContainer = inline ? FormInputContainerInline : React.Fragment;

    const labelCellStyle = (item.size && item.size.label) ? {
        width: item.size.label + "px"
    } : {}

    if (item.size && item.size.left) {
        labelCellStyle.paddingLeft = item.size.left + "px"
    }

    if (item.size && item.size.color) {
        labelCellStyle.color = item.size.color
    }

    if (item.size && item.size.textsize) {
        labelCellStyle.fontSize = item.size.textsize
    }

    const fieldCellStyle = (item.size && item.size.field) ? {
        width: item.size.field + "px"
    } : {}

    var disabled = false
    if ( item.disabled ) {
        disabled = item.disabled
    }

    if (item.type === 'display') {
        return (
            <FormInputContainer>
                <TableCell style={labelCellStyle}>{item.label}</TableCell>
                <TableCell style={fieldCellStyle}>
                    {value}
                </TableCell>
            </FormInputContainer>
        );
    }

    if (item.type === 'displaylink') {
        return (
            <FormInputContainer>
                <TableCell style={labelCellStyle}>{item.label}</TableCell>
                <TableCell style={fieldCellStyle}>
                    <a href={item.link} target="_blank" rel="noopener noreferrer">{value}</a>
                </TableCell>
            </FormInputContainer>
        );
    }

    if (item.type === 'radio') {

        var stringValue = "0"

        if ( (value === 1) || (value === "1") ) {
            stringValue = "1"
        }
        return (
            <FormInputContainer>
                <TableCell style={labelCellStyle}>{item.label}</TableCell>
                <TableCell style={fieldCellStyle}>

                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="position" name="position" value={stringValue} onChange={event => onChange(parseInt(event.target.value))} >
                            <FormControlLabel value="0" control={<Radio />} label={item.label_1} labelPlacement="end" />
                            <FormControlLabel value="1" control={<Radio />} label={item.label_2} labelPlacement="end" />
                        </RadioGroup>
                    </FormControl>
                </TableCell>
            </FormInputContainer>
        )
    }

    if (item.type === 'duration') {
        return (
            <FormInputContainer>
                <TableCell style={labelCellStyle}>{item.label}</TableCell>
                <TableCell style={fieldCellStyle}>
                    <DurationInputMask /*onChange={}*/ value={value} default={value} handleChange={onChange /*(parsedValue, maskedValue, rawValue) => {console.log('handleChange', parsedValue, maskedValue, rawValue)}*/} />
                </TableCell>
            </FormInputContainer>
        )
    }

    if (item.type === 'checkbox') {
        return (
            <FormInputContainer>
                <TableCell>
                    <Checkbox style={(item.size && item.size.label) ? {paddingLeft: item.size.label + "px"} : {paddingLeft: '0px'} } onChange={event => onChange(event.target.checked?1: 0)} checked={!!value}  disabled={disabled}/>
                    <span className="label">{item.label}</span>
                </TableCell>
            </FormInputContainer>
        )
    }

    if (item.type === 'text') {

        const HTMLAttrs = {
            ...(onChange ? { onChange }: {}),
            value: value || "",
            style: {
                ...((item.size && item.size.field) ? { width: item.size.field + "px", flex: 'none'} : { width: '100%'}),
                fontFamily: "Nunito"
            }
        }

        return (
            <FormInputContainer>
                <TableCell style={labelCellStyle}>{item.label}</TableCell>
                <TableCell style={fieldCellStyle}>
                    {item.multi ?
                        <textarea {...HTMLAttrs} onChange={e => onChange(e.target.value)} rows={item.multi} /> :
                        <TextFields config={item} {...HTMLAttrs} />
                    }
                </TableCell>
            </FormInputContainer>
        );
    }

    if (item.type === 'textint') {

        const HTMLAttrs = {
            ...(onChange ? { onChange }: {}),
            value: value || "",
            style: {
                ...((item.size && item.size.field) ? { width: item.size.field + "px", flex: 'none'} : { width: '100%'}),
                fontFamily: "Nunito"
            }
        }

        return (
            <FormInputContainer>
                <TableCell style={labelCellStyle}>{item.label}</TableCell>
                <TableCell style={fieldCellStyle}>
                    {item.multi ?
                        <textarea {...HTMLAttrs} onChange={e => onChange(e.target.value)} rows={item.multi} /> :
                        <TextFields config={item} {...HTMLAttrs} />
                    }
                </TableCell>
            </FormInputContainer>
        );
    }

    if (item.type === "select") {
        return (
            <FormInputContainer>
                <TableCell style={labelCellStyle}>{item.label}</TableCell>
                <TableCell style={fieldCellStyle}>
                    <Selects
                        style={{ width: '100%', marginBottom: '10px' }}
                        config={item}
                        size="small"
                        variant='outlined'
                        value={value}
                        onChange={onChange}
                    />
                </TableCell>
            </FormInputContainer>
        )
    }

    if (item.type === "dateselector") {
        return (
            <FormInputContainer>
                <TableCell style={labelCellStyle}>{item.label}</TableCell>
                <TableCell style={fieldCellStyle}>
                    <DatePicker
                        value={value}
                        onChange={onChange}
                        displayFormat="LL"
                    />
                </TableCell>
            </FormInputContainer>
        )
    }


    if (item.type === "richtext") {

        const handleBlurAreaChange = (textAreaValue, event) => {
         //   console.log("JoditEditor handleBlurAreaChange: ", textAreaValue)
      //      onChange(textAreaValue)
        }

        return (
            <FormInputContainer>
                <TableCell style={labelCellStyle}>{item.label}</TableCell>
                <TableCell style={fieldCellStyle}>

                    <JoditEditor
                        config={configJoEditor}
                        value={value}
                        onBlur={handleBlurAreaChange}
                        onChange={(value) => {
                            onChange(value);
                        }}
                    />
                </TableCell>
            </FormInputContainer>
        )
    }

    if (item.type === 'richtext_biblio') {

        const handleBlurAreaChange = (textAreaValue, event) => {
           // onChange(textAreaValue)
        }
        
        return (
            <FormInputContainer>
                <TableCell style={labelCellStyle}>{item.label}</TableCell>
                <TableCell style={fieldCellStyle}>

                    <JoditEditor
                        config={configJoEditor_biblio}
                        value={value}
                        onBlur={handleBlurAreaChange}
                        onChange={(value) => {
                            onChange(value);
                        }}
                    />
                </TableCell>
            </FormInputContainer>
        )
    }

    return (
        <FormInputContainer>
            <TableCell style={labelCellStyle}>{item.label}</TableCell>
            <TableCell style={fieldCellStyle}>
                {item.type === 'autocomplete' ?
                    <Autocompletes
                        style={{ width: '100%', marginBottom: '10px' }}
                        config={item}
                        value={value}
                        onChange={onChange}
                    /> :
                    <TextField
                        size="small"
                        variant='outlined'
                        multiline={!!item.multi}
                        rows={item.multi}
                    />
                }
            </TableCell>
        </FormInputContainer>
    )
}

const Form = ({ form, data = {}, onChange, inline = false }) => {

    const content = form.map((item, index) => {

        const onChangeItem = attr_name => (onChange ? (value => {
            onChange({
                ...data,
                [attr_name]: value
            })
        }) : null);

        const getValue = item.getValue ? item.getValue : ((data, attr_name) => data[attr_name])

        if (item.length) {
            return (
                <Line key={index}>
                    <TableCell style={{padding: '0px', width: '100%'}}>
                        <Form
                            form={item}
                            data={data}
                            onChange={onChange}
                            inline
                        />
                    </TableCell>
                </Line>
            )
        } else {
            const content = (
                <FormItem
                    isFirst={index === 0}
                    item={item}
                    value={getValue(data, item.attr)}
                    onChange={onChangeItem(item.attr)}
                    inline={inline}
                />
            );
            return inline ? (
                <Grid item key={item.key || item.attr}>
                    {content}
                </Grid>
            ) : (
                <Line key={item.key || item.attr}>
                    {content}
                </Line>
            );
        }
    })

    return inline ? (
        <Grid container spacing={1}>
            {content}
        </Grid>
    ) : (
        <div>
            {content}
        </div>
    )
}

export default Form;