import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { createConsignation } from 'Api'
import  ConsignationLayout from './index';
import { CircularProgress } from '@material-ui/core'
import { checkTimeout } from 'Api/ApiCall'

export default () => {

    const history = useHistory();
    const [ loading, setLoading ] = useState(true);

    const loadData = async () => {
        checkTimeout()
        setLoading(false);
    }

    useEffect(() => {
        loadData();
    })

    let content = null;
    if (loading) {
        content = <CircularProgress />
    } else {
        content = (
            <ConsignationLayout
                layout="create"
                saveData={async data => {

                    const result = await createConsignation({ data });
                    return result;
                }}
                onSaved={consignation => {
                    history.push("/consignation/" + consignation.objectId);
                }}
            />  
        )
    }

    return (
        <>
           {content}
       </>
   )
}