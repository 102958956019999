import React from 'react';
import DataTable from 'components/DataTable'
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
} from '@material-ui/icons'
import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next'
import { useCurrentPermissionProfile } from 'redux/hooks';
import Moment from 'moment';

import Medias from 'data/Medias';
import { deleteMedia } from 'Api'
import { convertToDuration } from 'Util/Media'
import { sortAlphaNum } from 'Util/Sorting'


const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = Medias, actions, onChange, onClickEdit, headerActions }) => {

    const { t } = useTranslation();
    const permissionProfile = useCurrentPermissionProfile();
    const canDelete = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === 'adriana' || permissionProfile === 'avila' || permissionProfile === null;
    const canEdit = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === 'adriana' || permissionProfile === 'avila' || permissionProfile === 'regisseur' || permissionProfile === null;

    async function deleteMediaAsync(media) {

        await deleteMedia({ media });
        onChange(data.filter(e => e.objectid !== media.objectid))
    }

    data.sort(function (a, b) {

        if ( a.archive && b.archive ) {
            return sortAlphaNum(a.archive.toLowerCase(), b.archive.toLowerCase())
        }
        return -1
    });

    return (
        <Paper elevation={3}>
            {headerActions && (
                <div>
                    {headerActions}
                </div>
            )}
            <DataTable
                data={data}
                config={{
                    onClickRow: media => {
                        window.open("/media/" + media.id_media)
                    },
                    cells: [
                        { title: t('archive'), key: 'archive', width:'80px', bold:true },
                        { title: t('genre'), key: 'genre' },
                        { title: t('title'), key: 'titre' },
                        { title: t('annee'), key: 'date_parution', width:'100px' },
                        { title: t('duree'), key: 'duree', width:'80px' },
                    ],
                    actions: actions || [
                        ...(canEdit ? [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, media) => {
                                window.open("/media/" + media.id_media)
                            }
                        }, ] : []),
                        ...(canDelete ? [
                        {   
                            icon: <DeleteIcon />, title: "Delete",
                            onClick: (id, media) => {  
                                if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                    deleteMediaAsync(media)
                                }
                            }
                        } ] : [])
                    ]
                }}
                getCell={(item, attr_name) => {

                    if (attr_name === 'date_parution') {
                        if (item['date_parution']) { 
                            return Moment(item['date_parution']).format('YYYY')
                        }
                        return ""

                    } else if ( attr_name === 'duree' ) {

                        return convertToDuration(item['duree'])
                    }
                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>


    )
}