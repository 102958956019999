import { sortDateBis } from 'Util/Sorting'
import { calcMultipleDatas, historiquesWithNumero } from 'Util/OeuvreMultiple';
import { isOeuvreMultiple } from 'Util/Oeuvre'


export const containsGenre = (contact, genre) => {

    let result = false

    if (contact.genres_array) {
        contact.genres_array.forEach( one_genre => {
            if ( one_genre["value"] === genre ) {
                result = true
            }
        })
    }
    return result
}

export const addGenre = (contact, genreTag, genreName) => {

    if ( containsGenre(contact, genreTag) === false ) {
        contact.genres_array.push({value: genreTag, label: genreName})
    }

}

export const filtreOeuvresActuelles = (oeuvres, contactId) => {

     var found = []

     if ( oeuvres !== null ) {
   
        oeuvres.forEach(oeuvre => {

            if ( isOeuvreMultiple(oeuvre) === true ) {
            } else {
                if ( oeuvre.historiques ) {

                    oeuvre.historiques.sort(function (a, b) {
                        return sortDateBis(a["date_historique"], b["date_historique"], false)
                    });

                }

                if ( oeuvre.historiques.length > 0 ) {
                    const lastTransaction = oeuvre.historiques[0]

                    if ( lastTransaction && lastTransaction.nouveau_proprio.length>0 ) {
                        if ( lastTransaction.nouveau_proprio[0].objectid === contactId ) {
                            var oeuvre_actuelle = []

                            oeuvre_actuelle.objectid = oeuvre.objectid
                            oeuvre_actuelle.titre = oeuvre.titre
                            oeuvre_actuelle.archive = oeuvre.archive
                            oeuvre_actuelle.annee_creation = oeuvre.annee_creation
                            oeuvre_actuelle.derniere_localisation = oeuvre.derniere_localisation
                            oeuvre_actuelle.photo_items = oeuvre.photo_items
                            oeuvre_actuelle.assurances = oeuvre.assurances
                            oeuvre_actuelle.multiple = oeuvre.multiple
                            oeuvre_actuelle.largeur = oeuvre.largeur
                            oeuvre_actuelle.hauteur = oeuvre.hauteur
                            oeuvre_actuelle.profondeur = oeuvre.profondeur
                            oeuvre_actuelle.type_oeuvre = oeuvre.type
                            oeuvre_actuelle.type = lastTransaction.type
                            oeuvre_actuelle.date_historique = lastTransaction.date_historique

                            found.push(oeuvre_actuelle)
                        }
                    }
                }
            }
        })

    }
     return found
}

export const filtreOeuvresPassees = (oeuvres, contactId) => {

     var found = []
     
     if ( oeuvres !== null ) {

        oeuvres.forEach(oeuvre => {
            
            if ( oeuvre.multiples && oeuvre.multiples.length>0 ) {
                
            } else {

                oeuvre.historiques.sort(function (a, b) {
                    if ( a && b ) {
                        if ( a.date_historique && b.date_historique ) {
                            return a.date_historique > b.date_historique ? -1 : 1;
                        } else if ( a.date_historique ) {
                            return -1
                        } else if ( b.date_historique ) {
                            return 1
                        }
                    }
                    return -1
                });
                
                console.log("filtreOeuvresPassees oeuvre : ", oeuvre)

                if ( oeuvre.historiques.length > 0 ) {

                    oeuvre.historiques.forEach( transaction => {
                        console.log("filtreOeuvresPassees transaction : ", transaction)
                    if ( transaction.ancien_proprio.length>0 &&  transaction.ancien_proprio[0].objectid === contactId ) {
                     //   console.log("filtreOeuvresPassees nouveau_proprio : ", transaction.nouveau_proprio[0])
                        var oeuvre_passee = []
     
                        oeuvre_passee.objectid = oeuvre.objectid
                        oeuvre_passee.titre = oeuvre.titre
                        oeuvre_passee.archive = oeuvre.archive
                        oeuvre_passee.annee_creation = oeuvre.annee_creation
                        oeuvre_passee.derniere_localisation = oeuvre.derniere_localisation
                        oeuvre_passee.photo_items = oeuvre.photo_items
                        oeuvre_passee.assurances = oeuvre.assurances
                        oeuvre_passee.multiple = oeuvre.multiple
                        oeuvre_passee.type_oeuvre = oeuvre.type
                        oeuvre_passee.type = transaction.type
                        oeuvre_passee.date_historique = transaction.date_historique
     
                        found.push(oeuvre_passee)
                    }
                })

               /*     const lastTransaction = oeuvre.historiques[0]
                  //  console.log("filtreOeuvresPassees historiques")

                    if ( lastTransaction && lastTransaction.nouveau_proprio.length>0 ) {
                    //    console.log("filtreOeuvresPassees lastTransaction : ", lastTransaction)

                        if ( lastTransaction.nouveau_proprio[0].objectid !== contactId ) {
                       //     console.log("filtreOeuvresPassees nouveau_proprio : ", lastTransaction.nouveau_proprio[0])
                            var oeuvre_passee = []

                            oeuvre_passee.objectid = oeuvre.objectid
                            oeuvre_passee.titre = oeuvre.titre
                            oeuvre_passee.archive = oeuvre.archive
                            oeuvre_passee.annee_creation = oeuvre.annee_creation
                            oeuvre_passee.derniere_localisation = oeuvre.derniere_localisation
                            oeuvre_passee.photo_items = oeuvre.photo_items
                            oeuvre_passee.assurances = oeuvre.assurances
                            oeuvre_passee.multiple = oeuvre.multiple
                            oeuvre_passee.type_oeuvre = oeuvre.type
                            oeuvre_passee.type = lastTransaction.type
                            oeuvre_passee.date_historique = lastTransaction.date_historique

                            found.push(oeuvre_passee)
                        }
                    }*/
                }
            }
        })
    }

     return found
}

export const filtreMultiplesActuels = (oeuvres, contactId) => {

     var found = []

     if ( oeuvres !== null ) {
   
        oeuvres.forEach(oeuvre => {
            
            const multiples = oeuvre.multiples

            if ( multiples && multiples.length>0 ) {

                calcMultipleDatas(oeuvre)

                multiples.forEach( multiple => {

                //    console.log("oeuvre : ", oeuvre)
               //     console.log("multiple : ", multiple)

                    const historiques = historiquesWithNumero(oeuvre.historiques, multiple.numero)

                    const lastTransaction = historiques[0]

                    if ( lastTransaction && lastTransaction.nouveau_proprio.length>0) {

                     //   console.log("lastTransaction : ", lastTransaction)

                        if ( lastTransaction.nouveau_proprio[0].objectid === contactId ) {
                            const multiple_actuel = []

                            multiple_actuel.objectid = oeuvre.objectid
                            multiple_actuel.numero = multiple.numero
                            multiple_actuel.titre = oeuvre.titre
                            multiple_actuel.archive = oeuvre.archive
                            multiple_actuel.annee_creation = oeuvre.annee_creation
                            multiple_actuel.derniere_localisation = oeuvre.derniere_localisation
                            multiple_actuel.photo_items = oeuvre.photo_items
                            multiple_actuel.type_oeuvre = oeuvre.type
                            multiple_actuel.type = lastTransaction.type
                            multiple_actuel.date_historique = lastTransaction.date_historique

                            found.push(multiple_actuel)
                        }
                    } else {
                        // si pas de transaction et l'état est déterminé alors c'est un multiple qui appartient à sarl Avila
                        const etat = multiple.etat

                        if ( (etat === "averifier")
                            || (etat === "depotvente")
                            || (etat === "disponible")
                            || (etat === "pret") 
                            || (etat === "nonpresentable")
                            || (etat === "reserve") ) {
                            
                            if ( contactId === 80760 ) {   // si le contactId est celui de sarl Avila
                                var multiple_initial = []

                                multiple_initial.objectid = oeuvre.objectid
                                multiple_initial.numero = multiple.numero
                                multiple_initial.titre = oeuvre.titre
                                multiple_initial.archive = oeuvre.archive
                                multiple_initial.annee_creation = oeuvre.annee_creation
                                multiple_initial.derniere_localisation = oeuvre.derniere_localisation
                                multiple_initial.filename = oeuvre.filename
                                multiple_initial.photo_items = oeuvre.photo_items
                                multiple_initial.type_oeuvre = oeuvre.type

                                //console.log("multiple_initial : ", oeuvre)
                                found.push(multiple_initial)
                            }
                        }
                    }
                })
            }
        })
    }
    return found
}

export const filtreMultiplesPasses = (oeuvres, contactId) => {

    var found = []

    if ( oeuvres !== null ) {

        oeuvres.forEach(oeuvre => {

            const multiples = oeuvre.multiples

            if ( multiples && multiples.length>0 ) {

                calcMultipleDatas(oeuvre)
               // console.log("oeuvre :", oeuvre)

                multiples.forEach( multiple => {
                    
                    const historiques = historiquesWithNumero(oeuvre.historiques, multiple.numero)
                    var compteurTransaction = 0
                    historiques.forEach( transaction => {

                        if ( compteurTransaction === 0 ) {

                            if ( transaction.ancien_proprio.length>0) {

                                if ( transaction.ancien_proprio[0].objectid === contactId ) {
                                    let multiple_passe = []

                                    multiple_passe.objectid = oeuvre.objectid
                                    multiple_passe.numero = multiple.numero
                                    multiple_passe.titre = oeuvre.titre
                                    multiple_passe.archive = oeuvre.archive
                                    multiple_passe.annee_creation = oeuvre.annee_creation
                                    multiple_passe.filename = oeuvre.filename
                                    multiple_passe.photo_items = oeuvre.photo_items
                                    multiple_passe.type_oeuvre = oeuvre.type
                                    multiple_passe.type = transaction.type
                                    multiple_passe.date_historique = transaction.date_historique

                                    found.push(multiple_passe)
                                }
                            }
                        } else {

                            if ( transaction.nouveau_proprio.length>0) {

                                if ( transaction.nouveau_proprio[0].objectid === contactId ) {
                                    let multiple_passe = []

                                    multiple_passe.objectid = oeuvre.objectid
                                    multiple_passe.numero = multiple.numero
                                    multiple_passe.titre = oeuvre.titre
                                    multiple_passe.archive = oeuvre.archive
                                    multiple_passe.annee_creation = oeuvre.annee_creation
                                    multiple_passe.filename = oeuvre.filename
                                    multiple_passe.photo_items = oeuvre.photo_items
                                    multiple_passe.type_oeuvre = oeuvre.type
                                    multiple_passe.type = transaction.type
                                    multiple_passe.date_historique = transaction.date_historique

                                    found.push(multiple_passe)
                                }
                            }
                        }
                        compteurTransaction = compteurTransaction + 1
                    })
                })
            }
        })
    }
    return found

}
