import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { createPhoto } from 'Api'
import PhotoLayout from './index';
import { CircularProgress } from '@material-ui/core'
import { checkTimeout } from 'Api/ApiCall'

export default () => {

    const history = useHistory();
    const [ loading, setLoading ] = useState(true);

    const loadData = async () => {
        checkTimeout()
        setLoading(false);
    }

    useEffect(() => {
        loadData();
    })

    let content = null;
    if (loading) {
        content = <CircularProgress />
    } else {
        content = (
        <PhotoLayout
            layout="create"
            saveData={async data => {

                const result = await createPhoto({ data });
                return result;
            }}
            onSaved={photo => {
                history.push("/photo/" + photo.objectId);
            }}
        />
        )
    }

    return (
        <>
           {content}
       </>
   )
}