// @flow
import React from 'react';
import Form from 'components/Form';
import { useTranslation } from 'react-i18next'

type Props = {
    data: any,
    onChange: any => any
}
export default ({ data, onChange }: Props) => {

    const { t } = useTranslation();

    return (
        <React.Fragment>

            <Form
                form={[
                    { label: "Nom", attr: "last_name", type: "text", size: { label: 150, field: 300 } },
                    { label: "Prénom", attr: "first_name", type: "text", size: { label: 150, field: 300 } },
                    { label: "Email", attr: "email", type: "text", size: { label: 150, field: 300 } },

                    {
                        label: "Type", attr: 'permission_profile', type: "select",
                        options : [
                            { label: t("Admin"), value: "admin"},
                            { label: t("Avila"), value: "avila"},
                            { label: t("Comité"), value: "comite"},
                            { label: t("Comité consultation"), value: "comite_consultation"},
                            { label: t("Invité"), value: "invite"},
                            { label: t("Régisseur"), value: "regisseur"},
                            { label: t("Accès_Lecture"), value: "acces_lecture"},
                            { label: t("Stagiaire"), value: "stagiaire"},
                            { label: t("Adriana"), value: "adriana"},
                            { label: t("Isabelle"), value: "isabelle"},
                        ], size: { label: 150, field: 300 }
                    },

                    {
                        label: "Type", attr: 'activ', type: "select",
                        options : [
                            { label: t("access_granted"), value: true},
                            { label: t("access_denied"), value: false},
                        ], size: { label: 150, field: 300 }
                    },
                    { label: "Adresses IP", attr: "ip_addresses", type: "text", multi:'5', size: { label: 150, field: 300 } },
                ]}
                data={data}
                onChange={onChange}
            />

        </React.Fragment>

    )
}
