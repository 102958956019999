import * as types from '../constants';

export default function reducer(state = {}, actions) {

  //  console.log("list reducer : ", actions)

    if (!actions.list_type) {
        return state;
    }

    switch (actions.type) {

        case types.SET_LIST_RESULTS:

            return {
                ...state,
                [actions.list_type]: {
                    ...(state[actions.list_type] || {}),
                    results: actions.results
                }
            }

        default:
            return state
    }
}
