import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { createDessin } from 'Api'
import DessinLayout from './index';
import { CircularProgress } from '@material-ui/core'
import { checkTimeout } from 'Api/ApiCall'

export default () => {

    const history = useHistory();
    const [ loading, setLoading ] = useState(true);

    const loadData = async () => {
        checkTimeout()
        setLoading(false);
    }

    useEffect(() => {
        loadData();
    })

    let content = null;
    if (loading) {
        content = <CircularProgress />
    } else {
        content = (
        <DessinLayout
            layout="create"
            saveData={async data => {

                const result = await createDessin({ data });
                return result;
            }}
            onSaved={dessin => {
                history.push("/dessin/" + dessin.objectId);
            }}
        />
        )
    }
    
    return (
        <>
           {content}
       </>
   )
}