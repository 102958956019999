import * as types from '../constants';

export default function reducer(state = { data: [] }, actions) {


    switch (actions.type) {

        case types.SET_CRITERES:
            return {
                ...state,
                data: actions.data
            }

        default:
            return state
    }
}
