import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import fr from 'Trads/fr'
import en from 'Trads/en'
import es from 'Trads/es'

const resources = {
  en: {
    translation: en
  },
  'en-GB': {
    translation: en
  },
  'en-US': {
    translation: en
  },

  fr: {
    translation: fr
  },
  'fr-FR': {
    translation: fr
  },
  es: {
    translation: es
  },
  'es-ES': {
    translation: es
  }
};

const AvilaDetector = {
  name: 'AvilaDetector',

  lookup(options) {
    // options -> are passed in options
    const lang = localStorage.getItem("lang");
    return lang || 'fr';
  },

  cacheUserLanguage(lng, options) {
 //   console.log("options");
    localStorage.setItem("lang", lng);
  },
};
const languageDetector = new LanguageDetector();
languageDetector.addDetector(AvilaDetector);

i18n
  .use(languageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      order: ['AvilaDetector'],
      caches: ['AvilaDetector']
    },
    resources,
    useLocalStorage: true,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;