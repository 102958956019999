// @flow
import React from 'react';

import Form from 'components/Form';
import { useTranslation } from 'react-i18next'

type Props = {
    data: any,
    isMultiple: any,
    onChange: any => any
}
export default ({ data, isMultiple, onChange }: Props) => {
    
    const { t } = useTranslation();

    data["type"] = "estimation"
   // console.log("isMultiple :"  , isMultiple)

    return (
        <React.Fragment>

            <Form
                form={[
                    { label: t("date"), attr: "date_enchere", type: "dateselector", size: { label: 150, field:150 }},
                    { label: t("interlocuteur"), attr: "source", type: "text", size: { label: 150, field:400 }},
                    { label: t("devise"), attr: 'devise_estimation', type: "select", options : [
                        { label: "Euros", value: "Euros"},
                        { label: "Dollars", value: "Dollars"},
                    ], size: { label: 150, field:200 }},
                    { label: t("estimation_basse"), attr: "estimation_min", type: "text", size: { label: 150, field:100 }},
                    { label: t("estimation_haute"), attr: "estimation_max", type: "text", size: { label: 150, field:100 }},
                    ...(isMultiple ? [
                        { label: t("numero_exemplaire"), attr: "multiple", type: "text", size: { label: 150, field:400 }},
                    ] : [] ),
                    { label: t("ville"), attr: "ville", type: "text", size: { label: 150, field:300 }},
                    {
                        label: t("pays"), attr: 'pays', type: "select",
                            selectType: 'countries', size: { label: 150, field: 300 }
                        },
                    { label: t("remarques"), attr: "remarques", type: "text", size: { label: 150, field:400 }},
                ]}
                data={data}
                onChange={onChange}
            />

        </React.Fragment>

    )
}
