// @flow
import React from 'react';

import Form from 'components/Form';
import { useTranslation } from 'react-i18next'
//import { getPoundsFromKg } from "Util/Divers"

type Props = {
    data: any,
    onChange: any => any
}

export default ({ data, isMultiple, onChange }: Props) => {

    const { t } = useTranslation();

    data.poids_oeuvre_double = data.poids / 100
    data.poids_caisse_double = data.poids_caisse / 100
    data.poids_oeuvre_caisse_double = data.poids_oeuvre_caisse / 100
    
  //  const poids_oeuvre_caisse_string = data.poids_oeuvre_caisse_double + " kg / (" + getPoundsFromKg(data.poids_oeuvre_caisse_double) + " lbs)"
 //   data.poids_oeuvre_caisse_string = poids_oeuvre_caisse_string

    return (
        <React.Fragment>

            <Form
                form={[
                    { label: t("nimp15"), attr: "nimp15", type: "checkbox", size: { label: 150, field:150 }},
                    
                    { label: t("largeur"), attr: "largeur", type: "text", textType: 'measure', size: { label: 150, field:300 }},
                    { label: t("profondeur"), attr: "profondeur", type: "text", textType: 'measure', size: { label: 150, field:300 }},
                    { label: t("hauteur"), attr: "hauteur", type: "text", textType: 'measure', size: { label: 150, field:300 }},
                    
                    { label: t("poids_oeuvre"), attr: "poids_oeuvre_double", type: "text", textType: 'weight', size: { label: 150, field:300 }},
                    { label: t("poids_caisse"), attr: "poids_caisse_double", type: "text", textType: 'weight', size: { label: 150, field:300 }},
                    { label: t("poids_oeuvre_caisse"), attr: "poids_oeuvre_caisse_double", type: "text", textType: 'weight', size: { label: 150, field:300 }},
                //    { label: t("poids_oeuvre_caisse"), attr: "poids_oeuvre_caisse_string", type: "display"},
                    ...(isMultiple ? [
                        { label: t("numero_exemplaire"), attr: "multiple", type: "text", size: { label: 150, field:400 }},
                    ] : [] ),
                    { label: t("etat_de_la_caisse"), attr: "etat", type: "text", size: { label: 150, field:400 }},
                    { label: t("remarques"), attr: "remarques", type: "text", multi:3, size: { label: 150, field:500 }},

                    [
                        { label: t("cadre_de_voyage"), attr: "cadre_de_voyage", type: "checkbox", size: { label: 150, field:150 }},
                        { label: t("ouverture_verticale"), attr: "ouverture_verticale", type: "checkbox", size: { left: 100, field:150 }},
                        { label: t("peinte"), attr: "peinte", type: "checkbox", size: { left: 100, field:150 }},
                    ],
                    [
                        { label: t("a_refaire"), attr: "a_refaire", type: "checkbox", size: { label: 150, field:150 }},
                        { label: t("tyvek_ok"), attr: "tyvek_ok", type: "checkbox", size: { label: 50, field:150 }},
                        { label: t("cales_ok"), attr: "cales_ok", type: "checkbox", size: { label: 65, field:150 }},
                    ],
               ]}
                data={data}
                onChange={ (retour) => {
                    //   console.log("Transport form : ", retour)
                        retour.poids = retour.poids_oeuvre_double * 100
                        retour.poids_caisse = retour.poids_caisse_double * 100
                        retour.poids_oeuvre_caisse = retour.poids_oeuvre_caisse_double * 100
                     //   retour.poids_oeuvre_caisse = retour.poids + retour.poids_caisse
                        onChange(retour)
                    }
                }
            />

        </React.Fragment>

    )
}
