import React, { useState } from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { useTranslation } from 'react-i18next';

import { NavLink as RouterNavLink, withRouter } from "react-router-dom";
import { darken } from "polished";

import PerfectScrollbar from "react-perfect-scrollbar";
import "../vendor/perfect-scrollbar.css";

import {
    Chip,
    Collapse,
    Drawer as MuiDrawer,
    Grid,
    List as MuiList,
    ListItem,
    ListItemText,
    Typography,
    Button
} from "@material-ui/core";

import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { dashboard as routes } from "../routes/index";

import LogoSrc from 'Assets/Logo/soto.png';
import { useAuth } from "redux/hooks";
import { useCurrentPermissionProfile } from 'redux/hooks';

const LogoImg = styled.img`
  width: 100%;
`;
const LOGO = () => (
  <LogoImg src={LogoSrc} alt="Logo" />
)

const NavLink = React.forwardRef((props, ref) => (
    <RouterNavLink innerRef={ref} {...props} />
));

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${props => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${props => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${props => props.theme.spacing(2.5)}px;
  padding-bottom: ${props => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${props => props.theme.typography.h5.fontSize};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.sidebar.header.color};
  background-color: ${props => props.theme.sidebar.header.background};
  font-family: ${props => props.theme.typography.fontFamily};
  min-height: 56px;
  /* padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(6)}px; */
  padding: 0;

  ${props => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;


const Category = styled(ListItem)`
  padding-top: ${props => props.theme.spacing(3)}px;
  padding-bottom: ${props => props.theme.spacing(3)}px;
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(5)}px;
  font-weight: ${props => props.theme.typography.fontWeightRegular};

  svg {
    color: ${props => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${props => props.activeClassName} {
    background-color: ${props => darken(0.05, props.theme.sidebar.background)};

    span {
      color: ${props => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${props => props.theme.sidebar.color};
    font-size: ${props => props.theme.typography.body1.fontSize}px;
    font-weight: ${props => props.theme.sidebar.category.fontWeight};
    padding: 0 ${props => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-left: ${props => props.theme.spacing(15)}px;
  padding-top: ${props => props.theme.spacing(2)}px;
  padding-bottom: ${props => props.theme.spacing(2)}px;

  span {
    color: ${props => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${props => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${props => props.activeClassName} {
    background-color: ${props => darken(0.06, props.theme.sidebar.background)};

    span {
      color: ${props => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${props => props.theme.sidebar.color};
  span {
    font-size: ${props => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${props => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${props => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${props => props.theme.sidebar.badge.color};
    padding-left: ${props => props.theme.spacing(2)}px;
    padding-right: ${props => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${props => props.theme.sidebar.color};
  padding: ${props => props.theme.spacing(4)}px
    ${props => props.theme.spacing(6)}px ${props => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const SidebarFooter = styled.div`
  background-color: ${props =>
        props.theme.sidebar.footer.background} !important;
  padding: ${props => props.theme.spacing(2.75)}px
    ${props => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled(Typography)`
  color: ${props => props.theme.sidebar.footer.color};
`;


function SidebarCategory({
    name,
    icon,
    classes,
    isOpen,
    isCollapsable,
    badge,
    ...rest
}) {
    return (
        <Category {...rest}>
            {icon}
            <CategoryText>{name}</CategoryText>
            {isCollapsable ? (
                isOpen ? (
                    <CategoryIconMore />
                ) : (
                        <CategoryIconLess />
                    )
            ) : null}
            {badge ? <CategoryBadge label={badge} /> : ""}
        </Category>
    );
}

function SidebarLink({ name, to, badge }) {
    return (
        <Link
            button
            dense
            component={NavLink}
            exact
            to={to}
            activeClassName="active"
        >
            <LinkText>{name}</LinkText>
            {badge ? <LinkBadge label={badge} /> : ""}
        </Link>
    );
}

function Sidebar({ classes, staticContext, location, ...rest }) {

    const auth = useAuth();
    const userEmail = (auth.user && auth.user.email) || "";

    const initOpenRoutes = () => {
        /* Open collapse element that matches current url */
        const pathName = location.pathname;

        let _routes = {};

        routes.forEach((route, index) => {
            var isActive = pathName.indexOf(route.path) === 0;
            var isOpen = route.open || route.alwaysOpen;
            const isHome = route.containsHome && pathName === "/" ? true : false;
            
            _routes = Object.assign({}, _routes, { [index]: isActive || isOpen || isHome })
        });

        return _routes;
    };

    const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

    const toggle = index => {
        // Collapse all elements
        // Object.keys(openRoutes).forEach(
        //     item => openRoutes[index] || setOpenRoutes(openRoutes => Object.assign({}, openRoutes, { [item]: false }))
        // )

        // Toggle selected element
        setOpenRoutes(openRoutes => Object.assign({}, openRoutes, { [index]: !openRoutes[index] }));
    }

    const hasSubSideBar = item => (item.children && item.children.length && item.children.filter(sub => {
      return sub.sidebar || (sub.children && hasSubSideBar(sub))
    }).length > 0)


    const { t, i18n } = useTranslation();

    const setLanguage = lng => {
      i18n.changeLanguage(lng);
    }
    const getCurrentLanguage = () => {
      return i18n.language.split('-')[0]
    }

    const getItemName = item => {

      if (typeof item.name === 'function') {
        return item.name(t)
      }

      return item.name;
    }

      const permissionProfile = useCurrentPermissionProfile();


    var userCanSeeExpo = false
    var userCanSeeBiblio = false
    var userCanSeeConsignation = false
    var userCanSeePhoto = false
    var userCanSeeContact = false
    var userCanSeeMedia = false
    var userCanSeeDessin = false
    var userCanOptions = false
    var userCanSeeUsers = false
    var userCanSeeMaintenance = false
 
   // var userCanCreate = false

    if ( (permissionProfile === 'admin') || (permissionProfile === null) ) {

      userCanSeeExpo = true
      userCanSeeBiblio = true
      userCanSeeConsignation = true
      userCanSeePhoto = true
      userCanSeeContact = true
      userCanSeeMedia = true
      userCanSeeDessin = true
      userCanOptions = true
      userCanSeeUsers = true
      userCanSeeMaintenance = true
  //    userCanCreate = true

    } else if ( permissionProfile === 'avila' ) {

      userCanSeeExpo = true
      userCanSeeBiblio = true
      userCanSeeConsignation = true
      userCanSeePhoto = true
      userCanSeeContact = true
      userCanSeeMedia = true

    } else if ( permissionProfile === 'comite' ) {

      userCanSeeExpo = true
      userCanSeeBiblio = true
      userCanSeeConsignation = true
      userCanSeePhoto = true
      userCanSeeContact = true
      userCanSeeMedia = true
      userCanSeeDessin = true
    //  userCanSeeUsers = true
   //   userCanCreate = true

    } else if ( permissionProfile === 'comite_consultation' ) {

      userCanSeeExpo = true
      userCanSeeBiblio = true
      userCanSeeConsignation = true
      userCanSeePhoto = true
      userCanSeeContact = true
      userCanSeeMedia = true
      userCanSeeDessin = true

  } else if ( permissionProfile === 'adriana' ) {

      userCanSeeExpo = true
      userCanSeeBiblio = true
      userCanSeeConsignation = true
      userCanSeePhoto = true
      userCanSeeContact = true
      userCanSeeMedia = true

    } else if ( permissionProfile === 'isabelle' ) {

      userCanSeeExpo = true
      userCanSeeBiblio = true
      userCanSeeConsignation = true
      userCanSeePhoto = true
      userCanSeeContact = true
      userCanSeeMedia = true
      userCanSeeDessin = true

  } else if ( permissionProfile === 'regisseur' ) {

      userCanSeeExpo = true
      userCanSeeConsignation = true
      userCanSeePhoto = true

  } else if ( permissionProfile === 'stagiaire' ) {

      userCanSeeExpo = true
      userCanSeeBiblio = true
      userCanSeeConsignation = true
      userCanSeePhoto = true
      userCanSeeContact = true
      userCanSeeMedia = true
      
  } else if ( permissionProfile === 'acces_lecture' ) {

    userCanSeeExpo = true
    userCanSeeBiblio = true
    userCanSeeConsignation = true
    userCanSeePhoto = true
    userCanSeeContact = true
    userCanSeeMedia = true
    userCanSeeDessin = true

  } else if ( permissionProfile === 'invite' ) {

  }
    

    const canDiplayCategory = category => {
    
      if ( (category.id === "Expos") && !userCanSeeExpo ) {
        return false
      }
      if ( (category.id === "Biblios/Presse") && !userCanSeeBiblio ) {
        return false
      }
      if ( (category.id === "Consignations") && !userCanSeeConsignation ) {
        return false
      }
      if ( (category.id === "Photos") && !userCanSeePhoto ) {
        return false
      }
      if ( (category.id === "Medias") && !userCanSeeMedia ) {
        return false
      }
      if ( (category.id === "Contacts") && !userCanSeeContact ) {
        return false
      }
      if ( (category.id === "Dessins") && !userCanSeeDessin ) {
        return false
      }
      if ( (category.id === "Options") && !userCanOptions ) {
        return false
      }
      if ( (category.id === "Maintenance") && !userCanSeeMaintenance ) {
        return false
      }
      if ( (category.id === "users") && !userCanSeeUsers ) {
        return false
      }
      return true
    }


    return (
        <Drawer variant="permanent" {...rest}>
            <Brand>
                <LOGO />
            </Brand>
            <Scrollbar>
                <List disablePadding>
                    <Items>
                        {routes.map((category, index) => (

                            !(category.sidebar || hasSubSideBar(category)) ? null : <React.Fragment key={index}>
                                {category.header ? (
                                    <SidebarSection>{category.header}</SidebarSection>
                                ) : null}

                                {category.children && !category.noMenu? (


                                   <React.Fragment key={index}>

                                  { canDiplayCategory(category) &&
                                        <SidebarCategory
                                            isOpen={!openRoutes[index]}
                                            isCollapsable={true}
                                            name={category.name ? getItemName(category) : category.id}
                                            icon={category.icon}
                                            button={true}
                                            onClick={() => toggle(index)}
                                        />}

                                      <Collapse in={openRoutes[index]} timeout="auto" unmountOnExit >
                                            {category.children.map((route, index) => (
                                                route.sidebar && <SidebarLink
                                                    key={index}
                                                    name={getItemName(route)}
                                                    to={route.path}
                                                    icon={route.icon}
                                                    badge={route.badge}
                                                />
                                            ))}
                                        </Collapse>

                                    </React.Fragment>
                                ) : (

                                  <>
                                    
                                    { canDiplayCategory(category) &&
                                      <SidebarCategory
                                            isCollapsable={false}
                                            name={category.id}
                                            to={category.path}
                                            activeClassName="active"
                                            component={NavLink}
                                            icon={category.icon}
                                            badge={category.badge}
                                      />}
                                    </>
                                )}
                            </React.Fragment>
                        ))}
                    </Items>
                </List>
            </Scrollbar>
            <SidebarFooter>
                <div style={{marginBottom: '10px', display:'flex', justifyContent:'center'}}>
                  <Button size="small" color="primary" variant={getCurrentLanguage() === 'fr' ? "contained" : "text"} onClick={() => setLanguage('fr')}>FR</Button>
                  <Button size="small" color="secondary" variant={getCurrentLanguage() === 'en' ? "contained" : "text"} onClick={() => setLanguage('en')}>EN</Button>
                  <Button size="small" color="secondary" variant={getCurrentLanguage() === 'es' ? "contained" : "text"} onClick={() => setLanguage('es')}>ES</Button>
                </div>
                <Grid container spacing={2}>
                    <Grid item>
                        <SidebarFooterText variant="body2">
                            {userEmail}
                        </SidebarFooterText>
                        <Link
                          button
                          dense
                          component={NavLink}
                          exact
                          to={"/auth/logout"}
                          activeClassName="active"
                        >
                          <LinkText>Se déconnecter</LinkText>
                      </Link>
                      
                    </Grid>
                </Grid>
            </SidebarFooter>
        </Drawer>
    );
}

export default withRouter(Sidebar);
