
import { getYear} from './Dates';
import {calcLastLocalisation} from './OeuvreLocalisation';
import {calcLastHistorique, displayProprio} from './OeuvreProprietaire';
import { sortAlphaNum } from 'Util/Sorting'

export const trierParExemplaire = (data) => {

    var multiples = data.multiples

    data.multiples.sort(function (a, b) {

        if ( a.numero && b.numero ) {
            return sortAlphaNum(a.numero.toLowerCase(), b.numero.toLowerCase())
        }
        return -1
    });

    return multiples
}


export const listeDesExemplaires = (multiples) => {

    var liste = []

      if ( multiples ) {    
        multiples.forEach(multiple => {  
            liste.push(multiple.numero)
        })
    }

    return liste
}


export const calcMultipleDatas = (data) => {

    if ( data.multiples ) {    
        data.multiples.forEach(multiple => {
        //    console.log("multiple : ", multiple)

            var localisations = localisationsWithNumero(data.localisations, multiple.numero)
            var lastLocalisation = calcLastLocalisation(localisations)

            multiple.derniere_localisation = displayMultipleLastLocalisation(lastLocalisation)

            if ( lastLocalisation && lastLocalisation.emplacement ) {
                multiple.emplacement = lastLocalisation.emplacement
                multiple.dernier_emplacement = lastLocalisation.emplacement
            }
            var historiques = historiquesWithNumero(data.historiques, multiple.numero)

          //  console.log("historiques : ", historiques)
            var lastHistorique = calcLastHistorique(historiques)
            //    console.log("lastHistorique : ", lastHistorique)

            if ( lastHistorique ) {
                multiple.dernier_proprietaire = displayMultipleProprietaire(lastHistorique)
                multiple.dernier_proprietaire_id = getLastProprietaireId(lastHistorique)
            } else {

                const etat = multiple.etat

                if ( (etat === "averifier")
                    || (etat === "depotvente")
                    || (etat === "disponible")
                    || (etat === "pret") 
                    || (etat === "nonpresentable")
                    || (etat === "reserve") ) {
                    multiple.dernier_proprietaire = "s.a.r.l AVILA"
                } else {
                    multiple.dernier_proprietaire = "Inconnu"
                }
            }
        })
    }
}

export const multipleWithNumero = (oeuvre, numero) => {

    var foundMultiple = null
    
    oeuvre.multiples.forEach(multiple => {
        
        if ( multiple.numero === numero) {
            foundMultiple = multiple
        }
    })

    return foundMultiple
}


export const localisationsWithNumero = (localisations, numero) => {

    var foundLocalisations = []
    
    
    if ( localisations ) {
       localisations.forEach(localisation => {

            if ( localisation.multiple === numero) {
                foundLocalisations.push(localisation)
            }
        })
    }

    return foundLocalisations
}

export const historiquesWithNumero = (historiques, numero) => {

    var foundHistoriques = []
    
    historiques.forEach(historique => {
        
        if ( historique.multiple === numero) {
            foundHistoriques.push(historique)
        }
    })

    return foundHistoriques
}



export const displayMultipleLastLocalisation = (lastLocalisation) => {

    var display = ""

    if ( lastLocalisation && lastLocalisation.localisation ) {
        display = lastLocalisation.localisation
        
        if ( lastLocalisation.date_localisation ) {
            display = display + " - " + getYear(lastLocalisation.date_localisation)
        }
    } else {
        display = "Localisation inconnue"
    }

    return display
}


export const displayMultipleProprietaire = (lastHistorique, date) => {

    if ( lastHistorique.nouveau_proprio && (lastHistorique.nouveau_proprio.length > 0) ) {
        var nouveau_proprio = lastHistorique.nouveau_proprio[0]
        var display = displayProprio(nouveau_proprio)
     
        if ( date === true && lastHistorique.date_historique ) {
            display = display + " - " + getYear(lastHistorique.date_historique)
        }   

        return display
    }
    return "pas de nouveau proprietaire"
}

export const getLastProprietaireId = (lastHistorique, date) => {

    if ( lastHistorique.nouveau_proprio ) {    
        return lastHistorique.nouveau_proprio
    }
    return -1
}

export const isSelectedMultiples = (selectedMultiples) => {

    const keys = Object.keys(selectedMultiples)
    var selected = false

    keys.forEach(key => {

        if (selectedMultiples[key] === true) {
            selected = true
        }
     })

    return selected
}
