import React from 'react';
import DataTable from 'components/DataTable'
import {
    Edit as EditIcon,
} from '@material-ui/icons'


import ArtWorks from 'data/ArtWorks';
import MuiPaper from '@material-ui/core/Paper';
import Image from 'components/Img'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components';

import TYPES from 'Assets/json/TypesOeuvre.json'
//import { XOctagon } from 'react-feather';

const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = ArtWorks, actions, onChange, onClickEdit, headerActions }) => {

    const { t } = useTranslation();
    const { i18n } = useTranslation();


    data.sort(function (a, b) {
        return a.archive < b.archive ? -1 : 1
    });

 //   console.log("find oeuvres : ", data)
    return (
        <Paper elevation={3}>
            {headerActions && (
                <div>
                    {headerActions}
                </div>
            )}
            <DataTable
                data={data}
                config={{
                    cells: [
                        { title: t('nb_archive'), key: 'archive', bold:true },
                        { title: t('title'), key: 'titre' },
                        { title: t('creation_year'), key: 'annee_creation' },
                        { title: t('type_doeuvre'), key: 'type' }
                    ],
                    actions: actions || [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, artwork) => {
                                window.open("/artwork/" + artwork.objectid)
                            }
                        },
                    ]
                }}
                getCell={(item, attr_name) => {

                    if (attr_name === "filename") {
                        return (
                            <Image
                                imageWidth={60}
                            style={{justifyContent: 'flex-start'}}
                                alt="" filename={item.filename}
                            />
                        )
                    } else if ( attr_name === "type") {

                        const type = item["type"]-1
                        if ( TYPES[type] ) {
                            return TYPES[type].loc[i18n.language]
                        }
                    }
                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>

    )
}