// @flow
import React from 'react';

import Form from 'components/Form';
import { useTranslation } from 'react-i18next'
import {listeDesLocalisations, indexInListeDesLocalisations, paysDesLocalisations, villeDesLocalisations, emplacementDesLocalisations } from 'Util/Listes'

type Props = {
    data: any,
    onChange: any => any
}

export default ({ data, onChange }: Props) => {

    const { t } = useTranslation();

    return (
        <React.Fragment>

            <Form
                form={[
                    { label: t("numero_exemplaire"), attr: "numero", type: "text", size: { label: 200, field:100 }},

                    { label: t("etat_administratif"), attr: 'etat', type: "select", options : [
                        { label: "A vérifier", value: "averifier"},
                        { label: "Dépôt-vente", value: "depotvente"},
                        { label: "Disponible", value: "disponible"},
                        { label: "Disponible - Modèle d'expo", value: "disponiblemodeledexpo"},
                        { label: "Donné", value: "donne"},
                        { label: "Non présentable", value: "nonpresentable"},
                        { label: "Prêt", value: "pret"},
                        { label: "Prêt - Modèle d'expo", value: "pretmodeledexpo"},
                        { label: "Réservé", value: "reserve"},
                        { label: "Vendu", value: "vendu"},
                    ], size: { label: 200, field:300 }},

                    { label: t("etat_de_conservation"), attr: 'etat_de_conservation', type: "select", options : [
                        { label: "A restaurer", value: "arestaurer"},
                        { label: "A vérifier", value: "averifier"},
                        { label: "Neuf", value: "neuf"},
                        { label: "Bon état", value: "bon"},
                        { label: "Moyen", value: "moyen"},
                        { label: "Moyen / Mauvais", value: "moyenmauvais"},
                        { label: "Mauvais", value: "mauvais"},
                    ], size: { label: 200, field:300 }},

                    [
                    { label: t("non_signe"), attr: "nonsigne", type: "checkbox", size: { label: 200, field:100 }},
                    { label: t("non_numerote"), attr: "nonnumerote", type: "checkbox", size: { label: 20, field:100 }},
                    ],

                    { label: t("conservation"), attr: "conservation", type: "text", multi:5, size: { label: 200, field:400 }},
                    { label: t("remarques"), attr: "remarques", type: "text", multi:5, size: { label: 200, field:400 }},

                    { label: t("localisation"), attr: "", type: "display", size: { label: 200, field:100 }},
                    { label: t("date"), attr: "date_localisation", type: "dateselector", size: { label: 200, field:150 }},
                    
                    { label: t("lieu"), attr: "localisation", type: "autocomplete", autocomplete: {
                            type: 'free',
                            options: listeDesLocalisations()
                        }, size: { label: 200, field:400 }
                    },
                    { label: t("emplacement"), attr: "emplacement", type: "autocomplete", autocomplete: {
                            type: 'free',
                            options: emplacementDesLocalisations()
                        }, size: { label: 200, field:400 }
                    },
                    { label: t("ville"), attr: "ville", type: "text", size: { label: 200, field:400 }},
                    { label: t("pays"), attr: 'pays', type: "select",
                        selectType: 'countries', size: { label: 200, field: 300 }
                    },
                    { label: t("remarques"), attr: "remarque_localisation", type: "text", size: { label: 200, field:400 }},
                ]}
                data={data}
                onChange={ (data) => {

                    const index = indexInListeDesLocalisations(data.localisation)

                    if ( index !== -1 ) {
                        const pays = paysDesLocalisations()[index]
                        data.pays = pays

                        const ville = villeDesLocalisations()[index]
                        data.ville = ville
                    }
                    onChange(data)
                }
            }
            />

        </React.Fragment>

    )
}
