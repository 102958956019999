export default {

    // COMMON
   "description" : "Descripción",
   "actions" : "Acciones",
   "cancel" : "Cancelar",
   "save" : "Guardar",
   "search" : "Buscar",
   "list" : "Lista",
   "new" : "Nuevo",
   "delete" : "Suprimir",
   "title"  : "Título",
   "other_title"  : "Otro título / Dedicatoria",
   "nb_archive" : "Archivo ",
   "archive" : "Archivo ",
   "date" : "Fecha ",
   "annee" : "Año ",
   "year" : "Año ",
   "creation_year" : "Año de creación ",
   "date_debut"  : "Fecha inicial ",
   "date_fin"  : "Fecha final ",
   "nom"  : "Apellido ",
   "prenom"  : "Nombre ",
   "adresse"  : "Dirección ",
   "adresse_2"  : "Dirección 2 ",
   "code_postal"  : "Código postal ",
   "ville"  : "Ciudad ",
   "pays"  : "País ",
   "phone"  : "Teléfono ",
   "email"  : "Email ",
   "prive"  : "Privado ",
   "size"  : "Tamaño ",
   "type"  : "Tipo ",
   "alerte_change_page"  : "No has guardado los cambios. ¿Estás seguro de que quieres salir de esta página?",
   "add_oeuvre"  : "Añadir obra ",
   "add_expo"  : "Añadir exposición ",
   "add_biblio"  : "Añadir bibliografía ",
   "add_media"  : "Añadir media ",
   "add_photo"  : "Añadir foto ",
   "add_dessin"  : "Añadir dibujo ",
   "add_caisse" : "Añadir caja ",
   "add_localisation": "Añadir localización",
   "add_estimation": "Añadir  estimación",
   "add_enchere": "Añadir subasta",
   "add_prix": "Añadir precio ",
   "add_assurance" : "Añadir seguro",

   "reserve" : "Reservado ",
   "back" : "Volver ",
   "annee_creation"  : "Año de creación ",
   "modifier" : "Cambiar",
    "changer" : "Cambiar",
   "changer_de_photo" : "Cambiar foto",

   "remarques" : "Observaciones ",
   "observations" : "Observaciones ",
   "copyright" : "Copyright ",
   "mots_clefs" : "Palabras clave ",
   "devise" : "Moneda ",
   "valeur" : "Valor ",
   "interlocuteur"  : "Interlocutor ",
   "estimation_basse"  : "Estimación baja ",
   "estimation_haute"  : "Estimación alta ",

   "date_de_creation_fiche"  : "Fecha de creación ficha ",
   "date_modification_fiche" : "Última modificación ",
   "exemplaire" : "Ejemplar ",

    // ARTWORK
   "artwork" : "Obra ",
   "artworks" : "Obras ",
   "oeuvre" : "Obra ",
   "oeuvres" : "Obras ",
   "annee_de_realisation" : "Realisación ",
   "type_doeuvre" : "Tipo de obra ",
   "familles" : "Familias ",
   "couleurs" : "Colores ",
   "materiaux" : "Materiales ",
   "elements" : "Elementos ",
   "nb_tes" : "núm. tes ",
   "nb_varillas" : "núm. varillas ",
   "nb_tacos" : "núm. tacos ",
   "oeuvre non identifiee"  : "Obra no identificada ",
   "criteres" : "Criterios",
   "caracteristiques_techniques_au_montage" : "Características técnicas de montaje",
   "details_techniques" : "Técnicas",
   "contraintes_dinstallation" : "Exigencias de instalación (dimensiones, peso)",
   "materiel_et_equipes_necessaires" : "Material y equipo necesario ",
   "autres_informations_techniques" : "Informaciones técnicas adicionales ",
   "caracteristiques_techniques" : "Características técnicas ",

   "largeur" : "Ancho ",
   "hauteur" : "Alto ",
   "profondeur" : "Profundidad ",
   "surface" : "Superficie ",
   "diametre" : "Diámetro ",
   "poids" : "Peso ",
   "dimensions_HT" : "Dimensiones HT ",
   "proprietaire_actuel" : "Propietario actual ",
   "localisation_actuelle" : "Localisación actual ",
   "proprietaire" : "Propietario ",
   "derniere_localisation" : "Localización ",
   "multiple" : "Múltiple ",
   "multiples" : "Múltiples ",
   "galerie" : "Galería ",
   "etat_administratif"  : "Estado administrativo ",
   "etat_de_conservation"  : "Estado de conservación ",
   "numero_exemplaire"  : "Número de ejemplar ",
   "numero"  : "Número ",
   "filtrer"  : "Filtrar ",
   "non_signe"  : "Sin firmar ",
   "non_numerote"  : "Sin número ",
   "edition"  : "Edición ",

   "code_avila" : "Code Avila",

   "localisation" : "Localización ",
   "global_localisation" : "Localización global ",
   "emplacement" : "Ubicación ",
   "etat_de_la_caisse" : "Estado de la caja ",
   "caisse_de_transport" : "Caja de transporte ",
   "conservation" : "Conservación/Restauración",
   "date_ouverture_caisse"  : "Fecha de apertura de la caja ",
   "date_ouverture" : "Fecha de apertura ",
   "date_dernier_constat" : "Fecha último informe de estado ",
   "realise_par" : "Realizado por ",
   "date_restauration" : "Fecha de restauración ",
   "restauration" : "Restauración ",
   "oeuvres_soeurs" : "Obras hermanas ",
   "fabrication" : "Fabricación ",
   "autres" : "Otros ",
   "historique" : "Procedencia ",
   "add_owner"  : "Añadir propietario ",
   "assurance" : "Seguro ",
   "prix" : "Precio ",
   "authentification" : "Autenticación ",
   "ajouter_une_demande"  : "Añadir solicitud ",
   "fabrique_par" : "Fabricado por ",
   "passeport"  : "Pasaporte ",
   "a_restaurer"  : "A restaurar ",
   "cadre_de_voyage"  : "Marco de viaje ",
   "a_refaire"  : "Rehacer ",
   "ouverture_verticale"  : "Apertura vertical ",
   "tyvek_ok"  : "Tyvek ok ",
   "peinte"  : "Pintada ",
   "nimp15"  : "NIMP 15 ",
   "cales_ok"  : "Cuñas OK ",

   "editeur" : "Editor ",
   "serie" : "Serie ",
   "tirage" : "Ejemplares ",
   "code" : "Código ",
   "plan" : "Plan ",
   "fiche_technique" : "Ficha técnica ",
   "date_fiche_technique" : "Fecha ficha técnica ",
   "code_comite"  : "Código Comité ",
   "ft_verifie_par_le_comite" : "Ficha técnica verificada por el Comité ",

   "etat_de_la_fiche" : "Estado de la ficha ",
   "doublon" : "Duplicado ",
   "inventaire" : "Inventario ",
   "disponible_a_la_vente" : "Disponible a la venta ",
   "alerte_sur_loeuvre" : "Alerta sobre la obra ",
   "previous_owner" : "Propietario anterior ",
   "new_owner" : "Nuevo propietario ",
   "document" : "Documento ",
   "en_cours" : "En curso ",
   "confirme" : "Confirmado ",
   "assureur" : "Asegurador ",
   "auction" : "Subasta ",
   "prix_au_marteau" : "Precio de martillo ",
   "prix_de_vente" : "Precio de venta ",
   "lot" : "Lote ",
   "estimation"  : "Estimación ",

   "contrat_envoye"  : "Contrato enviado ",
   "contrat_recu"  : "Contrato recibido ",
   "paiement"  : "Pago ",
   "document_didentite"  : "DNI ",
   "arrivee_oeuvre"  : "LLegada obra ",
   "retour_oeuvre"  : "Regreso obra ",
   "certificat"  : "Certificado ",
   "signature"  : "Firma ",
   "contrat_signe"  : "Contrato firmado ",
   "envoi_certificat"  : "Envío certificado ",
   "reception_certificat"  : "Recepción certificado ",
   "certificat_signe"  : "Certificado firmado ",
   "date_photo_hd"  : "Foto HD",
   "caisse"  : "Caja ",
    

    // BIBLIO
   "biblio_presse" : "Bibliografía/Prensa ",
   "biblios" : "Bibliografía ",
   "section" : "Sección ",
   "exemplaires" : "Ejemplares ",
   "type_de_document"  : "Tipo de documento ",
   "type_de_texte" : "Tipo de texto ",
   "nouvelle_info"  : "Nueva información ",
   "rangement" : "Ubicación ",
   "auteur" : "Autor ",
   "auteurs" : "Autores ",
   "bibliographie" : "Bibliografía ",
   "oeuvre_non_identifiee" : "Obra no identificada ",
   "texte_de_soto" : "Texto de Soto ",
   "texte_sur_soto" : "Texto sobre soto ",
   "entretien_avec_soto" : "Entrevista con Soto ",
   "transcription"  : "Transcripción ",
   "source_internet"  : "Fuente internet ",
   "page"  : "Página ",
   "reproduction_mention"  : "Reproducción / Mención",

    // CONSIGNATION
   "consignation" : "Consignación ",
   "consignations" : "Consignaciones ",
   "motif_de_consignation"  : "Motivo de consignación ",
   "emprunteur"  : "Prestatario ",
   "transport"  : "Transporte ",
   "contact"  : "Contacto ",
   "terminees"  : "Terminadas ",
   "contrat"  : "Contrato ",
   "vendu"  : "Vendido ",
   "pret"  : "Prestamo ",
   "retour"  : "Regreso ",
   "depart"  : "Salida ",
   "retour_valide"  : "Regreso validado ",
   "depart_valide"  : "Salida validada ",
   "consignation_terminee"  : "Consignación terminada ",

    // CONTACT
   "structure" : "Estructura ",
   "fonction" : "Foncción ",
   "genre_du_contact" : "Tipo de contacto ",
   "tel_perso" : "Teléfono personal ",
   "email_perso" : "EEmail personal ",
   "tel_pro" : "Teléfono profesional",
   "email_pro" : "Email profesional ",
   "tel_autre" : "Teléfono otro ",
   "email_autre" : "Email otro ",
   "site_web" : "Página web ",
   "oeuvres_detenues"  : "Obras actuales ",
   "oeuvres_detenues_passe"  : "Obras pasadas ",
   "create_new_contact"  : "Crear nuevo contacto ",
   "invitation" : "Invitación ",
   "date_acquisition"  : "Fecha de adquisición ",
   
    // DESSIN
   "dessin" : "Dibujo ",
   "dessins" : "Dibujos ",


    // EXPO
   "expo" : "Exposición ",
   "expos" : "Exposiciones ",
   "annee_debut" : "Fecha inicial ",
   "annee_fin" : "Fecha final ",
   "date_approximative" : "Fecha aproximada ",
   "lieu"  : "Lugar ",
   "type_expo" : "Tipo de exposición ",
   "dossier_dexpo"  : "Dosier de exposición ",
   "participants" : "Participantes ",
   "commissaire_curator"  : "Comisario/Curator ",

    // MEDIA
   "media" : "Media",
   "medias" : "Medias",
   "source" : "Fuente ",
   "format" : "Formato ",
   "genre" : "Tipo ",
   "langue" : "Idioma ",
   "langue_son"  : "Idioma/Sonido ",
   "sous_titre" : "Subtítulos ",
   "langue_soustitre" : "Idioma del subtítulo ",
   "duree" : "Duración ",
   "support_original" : "Soporte original ",
   "date_de_parution"  : "Fecha de publicación ",
   "timecode"  : "Timecode",

    // PHOTO
   "photo" : "Foto ",
   "photos" : "Fotos ",
   "photographe" : "Fotógrafo ",
   "localisation du support original" : "Localización del soporte original ",
   "identification personnes / oeuvres" : "Identificación personas / obras ",
   "localisation_exposition"  : "Localización / Exposición ",
   "code_photo"  : "Código foto ",
}
