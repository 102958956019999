import React from 'react';
import SearchContact from 'Api/Search/contact';
import Layout from 'pages/Avila/Contact/List/layout_find'

import SearchModal from './SearchModal';
/*import type { Props as SearchModalProps } from './SearchModal'

type Props = {
    onClose: any => any,
    actions: Array<any>,
    filterData: Array<any>
}*/

export default ({
    onClose,
    actions = [],
    filterData = [],
}: Props & SearchModalProps) => {

    return (
        <SearchModal
            title={"Ajouter un contact"}
            onClose={onClose}
            actions={actions}
            filterData={filterData}

            searchFunction={async query => {

                console.log("query : ", query)
                if ( query && query !== "" ) {
                    const nom_contacts = await SearchContact({ data: {nom : query} })
               //     const prenom_contacts = await SearchContact({ data: {prenom : query} })
               //     const structure_contacts = await SearchContact({ data: {structure : query} })
                
                    var contacts = []

                    contacts = contacts.concat(nom_contacts.data)
                //    contacts = nom_contacts.data.concat(structure_contacts.data)
                //    contacts = contacts.concat(structure_contacts.data)

                    contacts.sort(function (a, b) {
                        if ( a.nom && b.nom ) {
                            return a.nom.toLowerCase().localeCompare(b.nom.toLowerCase()) === 1 ? 1 : -1 ;
                        } else if ( a.nom ) {
                            return 1;
                        } else {
                            return -1
                        }
                    })

                    return contacts;
                }   
            }}

            renderList={({ data, actions }) => {

                return (
                    <Layout data={data} filterData={filterData} actions={actions} />
                )
            }}
        />
    )
}