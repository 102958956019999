import { getYear} from './Dates';
import { isOeuvreMultiple} from "Util/Oeuvre"


export const displayOeuvreLastProprio = (data) => {

    var lastTransaction = calcLastHistorique(data.historiques)
    var display = ""

    if ( lastTransaction ) {

        var nouveau_proprio = null

        if ( lastTransaction.nouveau_proprio && lastTransaction.nouveau_proprio.length > 0 ) {
            nouveau_proprio = lastTransaction.nouveau_proprio[0]
        }

        if ( nouveau_proprio ) {
           display =  displayProprio(nouveau_proprio)

            if ( lastTransaction.date_historique ) {
                display = display + " - " + getYear(lastTransaction.date_historique)
            }
        } else {
            display = "Inconnu"
        }   

    } else {
        display = "Inconnu"
    }
    return display
}

export const displayOeuvreLastProprioLight = (data) => {

    var lastTransaction = calcLastHistorique(data.historiques)
    var display = ""

    if ( lastTransaction ) {

        var nouveau_proprio = null

        if ( lastTransaction.nouveau_proprio != null ) {
            nouveau_proprio = lastTransaction.nouveau_proprio
            console.log("nouveau_proprio", nouveau_proprio)
        }

        if ( nouveau_proprio ) {
           display =  displayProprio(nouveau_proprio)
        } else {
            display = "Inconnu"
        }   

    } else {
        display = "Inconnu"
    }
    return display
}

export const getOeuvreLastProprioId = (data) => {

    var lastTransaction = calcLastHistorique(data.historiques)
    var display = -1

    if ( lastTransaction ) {

        var nouveau_proprio = null

        if ( lastTransaction.nouveau_proprio && lastTransaction.nouveau_proprio.length > 0 ) {
            nouveau_proprio = lastTransaction.nouveau_proprio[0]
        }

        if ( nouveau_proprio ) {
           display =  nouveau_proprio.objectid

        } else {
            display = -1
        }   

    } else {
        display = -1
    }
    return display
}


export const calcLastHistorique = (data) => {

    var lastHistorique = null
    
    if ( data && data.length > 0 ) {
        
        data.sort(function (a, b) {
            if ( a && b ) {
                if ( a.date_historique && b.date_historique ) {
                    return a.date_historique > b.date_historique ? -1 : 1;
                } else if ( a.date_historique ) {
                    return -1
                } else if ( b.date_historique ) {
                    return 1
                }
            }
            return -1
        });

        lastHistorique = data[0]
    }

    return lastHistorique
}

export const displayProprio = (data) => {

    var display = ""

    if ( data ) {

        if ( !data.nom && !data.prenom && !data.structure ) {
            display = "Inconnu"
        }
        
        if ( data.structure ) {
            display = data.structure + " "
        }
        if ( data.nom ) {
            display = display + " " + data.nom
        }
        if ( data.prenom ) { 
            display = display + " " + data.prenom 
        }

    } else {
        display = "Inconnu"
    }
    return display
}


export const authorizedOwner = (oeuvre) => {


    if ( isOeuvreMultiple(oeuvre) ) {
        return true
    }

 //   return true

    const dernier_proprietaire_id = getOeuvreLastProprioId(oeuvre)

    const authorizedOwners = [ 80052, // Mateo Cots Soto
                        80256,  // Adélie Frans Soto
                        80266,  // Alejandro Soto
                        80307,  // Baptiste Soto
                        80281,  // Anne Soto
                        80346,  // Christophe Soto
                        80447,  // Florence Soto
                        80569,  // Lucas Perez Soto
                        80601,  // Marina Soto
                        80760,  // s.a.r.l. Avila 
                        80770,  // Sorayane Frans Soto
                        80888,  // Collection Famille Soto
                        80889,  // Isabelle Soto
                        80912,  // Lucia Perez Soto
                        80987,  // Florence Adélie Sorayane Soto Frans
                        80987,  // Adélie Frans Soto
                        81072,   // Soto Pérez Anne Lucía Lucas
                        81021,  // Comité Soto
                        81028,  // Isabelle Mateo Soto Cots
                        81145,  // Lenny Delrot Frans Soto
                        81194,  // Christophe Baptiste Alejandro Marina Soto
                        81271,  // Ainhoa Delrot Frans Soto
                        81272  // Anne Lucia Lucas Soto Perez
    ]
    var authorized = false

    authorizedOwners.forEach( owner => {

        if ( owner === dernier_proprietaire_id ) {
            authorized = true
        }
    })
    return authorized
}


