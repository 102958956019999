import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { createOeuvre } from 'Api'
import ArtWorkLayout from './index';
import { CircularProgress } from '@material-ui/core'
import { checkTimeout } from 'Api/ApiCall'

export default () => {

    const history = useHistory();
    const [ loading, setLoading ] = useState(true);

    const loadData = async () => {
        checkTimeout()
        setLoading(false);
    }

    useEffect(() => {
        loadData();
    })

    let content = null;
    if (loading) {
        content = <CircularProgress />
    } else {
        content = (
        <ArtWorkLayout
            layout="create"
            saveData={async data => {
                const result = await createOeuvre({ data });
                return result;
            }}
            onSaved={artwork => {
                history.push("/artwork/" + artwork.objectId);
            }}
        />
        )
    }

    return (
         <>
            {content}
        </>
    )
}