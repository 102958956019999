import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'

import ArtworksPanel from '../../../ArtWork/SubPanel/index'

import {
    Grid,
    Paper
} from '@material-ui/core';

import Form from 'components/Form';
import Image from 'components/Img'
import { updateDessin } from 'Api'
import { getOeuvreElements, getOeuvreMateriaux, getOeuvreCouleurs, getOeuvreFamilles } from 'Util/OeuvreCriteres'
import { typeOeuvreString } from 'Util/Oeuvre'

import Tabs, {
    TabsPanels as OriginalTabsPanels
} from 'components/Tabs'

const TabsPanels = styled(OriginalTabsPanels)`
    padding:20px;
    padding-top: 0px;
`

const FormContainer = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const Box = styled(Paper)`
    padding:20px;
`

const GridContainerStyled = styled(Grid)`
    width: 100%;
`
const GridContainer = props => (
    <div style={{padding: "15px"}}>
        <GridContainerStyled container spacing={6} {...props} />
    </div>
)

export default ({ data = {}, reloadData = () => {}, onChange = () => {}, layout = 'editcreate' }) => {


    const { t } = useTranslation();
    const layoutIsSearch = layout === 'search';
    const layoutIsEdit = layout === 'edit'
    
    data.typeOeuvreString = typeOeuvreString(data.type_oeuvre)

    const form = [
        { label: t("archive"), attr: "archive", type: "text", size: { label: 150, field:100 }},

        ...(!layoutIsSearch ? [
            [
               { label: t("annee"), attr: "annee", type: "text", size: { label: 150, field:100 }},
               { label: "Circa", attr: "circa", type: "checkbox", size: { label: 20, left:20 } },
            ]
        ] : [
            [ { label: t("annee"), attr: "annee_min", type: "text", size: { label: 150, field:100 }},
            { label: "", attr: "annee_max", type: "text", size: { left :20, label: 0, field:100 }},]
        ]),

        { label: t("source"), attr: "copyright", type: "autocomplete", autocomplete: {
            type: 'free',
            options: [ "Archives Hélène", "Vente aux enchères"]
            }
            , size: { label: 150, field:300 }
        },
        { label: t("remarques"), attr: "identification", type: "text", multi:"5", size: { label: 150, field:300 }},
        { label: t("oeuvre non identifiee"), attr: "oeuvre_non_identifiee", type: "checkbox", size: { label: 150, field:100 } },
        { label: t("type_doeuvre"), attr: 'type_oeuvre', type: "select", selectType: 'artworktypes', size: { label: 150, field: 300 } },

    ]
 
    const form_2 = [
        ...(!layoutIsSearch ? [

        { label: t("hauteur"), attr: "hauteur", type: "text", textType: 'measure', size: { label: 150, field: 300 } },
        { label: t("largeur"), attr: "largeur", type: "text", textType: 'measure', size: { label: 150, field: 300 } },
        { label: t("profondeur"), attr: "profondeur", type: "text", textType: 'measure', size: { label: 150, field: 300 } },
        { label: t("diametre"), attr: "diametre", type: "text", textType: 'measure', size: { label: 150, field: 300 } },
        ] : [
            [{ label: t("hauteur"), attr: "hauteur", type: "text", textType: 'measure', size: { label: 150, field: 200 } },
            { label: "", attr: "hauteur_max", type: "text", textType: 'measure', size: { left:0, label: 0, field: 200 } },],

            [{ label: t("largeur"), attr: "largeur", type: "text", textType: 'measure', size: { label: 150, field: 200 } },
            { label: "", attr: "largeur_max", type: "text", textType: 'measure', size: { left:0,label: 0, field: 200 } },],

            [{ label: t("profondeur"), attr: "pronfondeur", type: "text", textType: 'measure', size: { label: 150, field: 200 } },
            { label: "", attr: "pronfondeur_max", type: "text", textType: 'measure', size: { left:0,label: 0, field: 200 } },],

            [{ label: t("diametre"), attr: "diametre", type: "text", textType: 'measure', size: { label: 150, field: 200 } },
            { label: "", attr: "diametre_max", type: "text", textType: 'measure', size: { left:0, label: 0, field: 200 } },],

        ]),
    ]

       const form_3 = [

        ...(!layoutIsSearch ? [
            { label: t("nb_tacos"), attr: "nb_tacos", type: "text", textType: 'int', size: { label: 150, field:100 } },
            { label: t("nb_varillas"), attr: "nb_varillas", type: "text", textType: 'int', size: { label: 150, field:100 } },
            { label: t("nb_tes"), attr: "nb_tes", type: "text", textType: 'int', size: { label: 150, field:100 } },
        ] : [
            [
                { label: t("nb_tacos"), attr: "nb_tacos", type: "text", textType: 'int', size: { label: 150, field:100 } },
                { label: "", attr: "nb_tacos_max", type: "text", size: { left:20, label: 0, field:100 } },
            ],
            [
                { label: t("nb_varillas"), attr: "nb_varillas", type: "text", textType: 'int', size: { label: 150, field:100 } },
                { label: "", attr: "nb_varillas_max", type: "text", size: { left:20, label: 0, field:100 } },
            ],
            [
                { label: t("nb_tes"), attr: "nb_tes", type: "text", textType: 'int', size: { label: 150, field:100 } },
                { label: "", attr: "nb_tes_max", type: "text", size: { left:20, label: 0, field:100 } },
            ],
        ]),

        
      //  ...(!layoutIsSearch ? [

            { label: t("familles"), attr: "familles_array", type: "autocomplete",
                autocomplete: {
                    type: 'local_tags',
                    options: getOeuvreFamilles()
                },
                size: { label: 150, field: 350 }
            },

            { label: t("elements"), attr: "elements_array", type: "autocomplete",
                autocomplete: {
                    type: 'local_tags',
                    options: getOeuvreElements()
                },
                size: { label: 150, field: 350 }
            },

            { label: t("couleurs"), attr: "couleurs_array", type: "autocomplete",
                autocomplete: {
                    type: 'local_tags',
                    options: getOeuvreCouleurs()
                },
                size: { label: 150, field: 350 }
            },

            { label: t("materiaux"), attr: "materiaux_array", type: "autocomplete",
                autocomplete: {
                    type: 'local_tags',
                    options: getOeuvreMateriaux()
                },
                size: { label: 150, field: 350 }
            },
      //  ] : [])
    ]


    async function updateDessinAsync(data) {
        await updateDessin({ id: data.objectid, data });
        reloadData(data)
    }

 //   const oeuvre_non_identifiee =  (data.oeuvre_non_identifiee === 1) || layoutIsSearch

    let exportFilename = "Dessin " + data.archive + ".jpg"
    

    return (

        <GridContainer>
            <Grid item xs={6}>

                <Box elevation={1} style={{position: 'relative', height: '100%'}}>
                    <FormContainer>
                        <Form form={form}  data={data} onChange={onChange} />
                    </FormContainer>
            
                    <FormContainer>
                        <Form form={form_2}  data={data} onChange={onChange} />
                    </FormContainer>

                    <FormContainer>
                        <Form form={form_3}  data={data} onChange={onChange} />
                    </FormContainer>
                </Box>
            </Grid>


            {!layoutIsSearch && <Grid item xs={6}>
                <Box elevation={1} style={{position: 'relative', height: '100%'}}>
                    <Image
                        onClickDelete={() => {
                            if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                onChange({
                                    ...data,
                                    filename: null
                                })
                            }
                        }}
                        onChange={filename => {
                            onChange({
                                ...data,
                                filename
                            })
                        }}
                        alt="test"
                        filename={data["filename"]}
                        downloadFile={exportFilename}
                        style={{width: '100%'}}
                        imgStyle={{maxWidth: '100%', maxHeight: '800px'}}
                        actionPosition='outside'
                    />                
                </Box>
            </Grid> }

            {layoutIsEdit && <Grid item xs={12}>
                <Tabs
                orientation={"horizontal"}
                variant="scrollable"
                initialIndex={0}
                tabs={[
                    {
                        label: t("oeuvres"),
                        panel: (
                            <TabsPanels>
                                <ArtworksPanel layout={layout} data={data} reloadData={reloadData} onChange={updateDessinAsync} />
                            </TabsPanels>
                        )
                    },
                ]}
            />
            </Grid>}
        </GridContainer>

    )
}
