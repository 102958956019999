export default {

    // COMMON
    "description": "Description",
    "cancel": "Cancel",
    "save": "Save",
    "search": "Search",
    "list": "List",
    "new": "New",
    "delete": "Delete",
    "title" : "Title",
    "other_title" : "Other title",
    "nb_archive": "Archive",
    "archive": "Archive",
    "date": "Date",
    "annee": "Year",
    "year": "Year",
    "creation_year": "Creation year",
    "date_debut" : "Start date",
    "date_fin" : "End date",
    "nom" : "Last name",
    "prenom" : "First name",
    "adresse" : "Address",
    "adresse_2" : "Address 2",
    "code_postal" : "Zipcode",
    "ville" : "City",
    "pays" : "Country",
    "phone" : "Phone",
    "email" : "Email",
    "prive" : "Private",
    "size" : "Size",
    "type" : "Type",
    "alerte_change_page" : "You didn't save your modifications. Do you really want to change page?",
    "add_artwork" : "Add an artwork",
    "add_oeuvre" : "Add an artwork",
    "add_expo" : "Add an expo",
    "add_biblio" : "Add a biblio",
    "add_media" : "Add a media",
    "add_photo" : "Addd a photo",
    "add_dessin" : "Add a drawing",
    "add_caisse": "Add a box",
    "add_localisation": "Add localisation",
    "add_estimation": "Add estimate",
    "add_enchere": "Add auction",
    "add_prix": "Add price",
    "add_assurance" : "Add an insurrance",

    "reserve": "Reserved",
    "back": "Back",
    "changer" : "Modify",
    "modifier" : "Modify",
    "changer_de_photo" : "Change photo",

    "remarques" : "Notes",
    "observations" : "Observations",
    "copyright": "Copyright",
    "mots_clefs": "Keywords",
    "devise": "Currency",
    "valeur": "Value",
    "interlocuteur" : "Interlocuteur",
    "estimation_basse" : "Low estimate",
    "estimation_haute" : "High estimate",

    "date_de_creation_fiche" : "Date de création fiche",
    "date_modification_fiche": "Dernière modification",
    "exemplaire": "Copy",

    // ARTWORK
    "artwork": "Artwork",
    "artworks": "Artworks",
    "oeuvre": "Artwork",
    "oeuvres": "Artworks",
    "annee_de_realisation": "Made",
    "type_doeuvre": "Kind of artwork",
    "familles": "Family", 
    "couleurs": "Colors",
    "materiaux": "Materials",
    "elements": "Elements",
    "nb_tes": "Nb of tees",
    "nb_varillas": "Nb of varillas",
    "nb_tacos": "Nb of tacos",
    "oeuvre non identifiee" : "Unknown artwork",
    "criteres": "Criterias",
    "caracteristiques_techniques_au_montage": "Technical specifications",
    "details_techniques": "Technics",
    "contraintes_dinstallation": "Contraintes d'installation (dimensions, poids)",
    "materiel_et_equipes_necessaires": "Matériel et équipes nécessaires",
    "autres_informations_techniques": "Other technical details",
    "caracteristiques_techniques": "Technical specifications",

    "largeur": "Width",
    "hauteur": "Height",
    "profondeur": "Depth",
    "surface": "Surface",
    "diametre": "Diameter",
    "poids": "Weight",
    "dimensions_HT": "Total dimensions",
    "proprietaire_actuel": "Current owner",
    "localisation_actuelle": "Current location",
    "proprietaire": "Owner",
    "derniere_localisation": "Location",
    "multiple": "Multiple",
    "multiples": "Multiples",
    "galerie": "Galerie",
    "etat_administratif" : "Etat administratif",
    "etat_de_conservation" : "Etat de conservation",
    "numero_exemplaire" : "Copy number",
    "numero" : "Number",
    "filtrer" : "Filter",
    "non_signe" : "Not signed",
    "non_numerote" : "No numbered",
    "edition" : "Edition",

    "code_avila" : "Code Avila",

    "localisation": "Location",
    "global_localisation": "Global location ",
    "emplacement": "Emplacement",
    "etat_de_la_caisse": "Box condition",
    "caisse_de_transport": "Box",
    "conservation": "Conservation/Restauration",
    "date_ouverture_caisse" : "Box last opening",
    "date_ouverture": "Box last opening",
    "date_dernier_constat": "Date dernier constat",
    "realise_par": "Done by",
    "date_restauration": "Restoration date",
    "restauration": "Restoration",
    "oeuvres_soeurs": "Sister artworks",
    "fabrication": "Fabrication",
    "autres": "Others",
    "historique": "Provenance",
    "add_owner" : "Add an owner",
    "assurance": "Insurrance",
    "prix": "Price",
    "authentification": "Authentification",
    "ajouter_une_demande" : "Add a request",
    "fabrique_par": "Made by",
    "passeport" : "Passeport",
    "a_restaurer" : "To restore",
    "cadre_de_voyage" : "Cadre de voyage",
    "a_refaire" : "A refaire",
    "ouverture_verticale" : "Ouverture verticale",
    "tyvek_ok" : "Tyvek OK",
    "peinte" : "Peinte",
    "nimp15" : "NIMP 15",
    "cales_ok" : "Cales OK",

    "editeur": "Publisher",
    "serie": "Serie",
    "tirage": "Tirage",
    "code": "Code",
    "plan": "Plan",
    "fiche_technique": "Fiche technique",
    "date_fiche_technique": "Date fiche technique",

    "code_comite" : "Code comité",
    "ft_verifie_par_le_comite": "Fiche technique vérifiée par le comité",

    "etat_de_la_fiche": "Etat de la fiche",
    "doublon": "Duplicate",
    "inventaire": "Inventory",
    "disponible_a_la_vente": "Available for sale",
    "alerte_sur_loeuvre": "Alerte sur l'oeuvre",
    "previous_owner": "Previous owner",
    "new_owner": "New owner",
    "document": "Document",
    "en_cours": "In progress",
    "confirme": "Confirmed",
    "assureur": "Insurer",
    "auction": "Auction",
    "prix_au_marteau": "Hammer price",
    "prix_de_vente": "Sale price",
    "lot": "Lot",
    "estimation" : "Estimation",

    "contrat_envoye" : "Contract sent",
    "contrat_recu" : "Contract received",
    "paiement" : "Payment",
    "document_didentite" : "Identity document",
    "arrivee_oeuvre" : "Artwork arrival",
    "retour_oeuvre" : "Artwork departure",
    "certificat" : "Certificat",
    "signature" : "Signature",
    "contrat_signe" : "Contract signed",
    "envoi_certificat" : "Sent certificate",
    "reception_certificat" : "Reception certificat",
    "certificat_signe" : "Certificat signed",
    "date_photo_hd" : "Photo HD",
    "caisse" : "Caisse",
    

    // BIBLIO
    "biblio": "Biblio/Press",
    "biblio_presse": "Biblios/Press",
    "biblios": "Biblios",
    "section": "Section",
    "exemplaires": "Copies",
    "type_de_document" : "Type de document",
    "type_de_texte": "Type de texte",
    "nouvelle_info" : "New information",
    "rangement": "Storage",
    "auteur": "Author",
    "auteurs": "Authors",
    "bibliographie": "Bibliographie",
    "oeuvre_non_identifiee": "Unidentified artwork",
    "texte_de_soto": "Text from Soto",
    "texte_sur_soto": "Text about Soto",
    "entretien_avec_soto": "Interview with Soto",
    "transcription" : "Transcription",
    "source_internet" : "Source Internet",
    "page" : "Page",
    "reproduction_mention" : "Reproduction / Mention",

    // CONSIGNATION
    "consignation": "Consignment",
    "consignations": "Consignments",
    "motif_de_consignation" : "Reason for consignment",
    "emprunteur" : "Borrower",
    "transport" : "Transport",
    "contact" : "Contact",
    "terminees" : "Finished",
    "contrat" : "Contract",
    "vendu" : "Sold",
    "pret" : "Loan",
    "retour" : "Return",
    "depart" : "Departure",
    "retour_valide" : "Return validated",
    "depart_valide" : "Departure validated",
    "consignation_terminee" : "Consignment finished",

    // CONTACT
    "structure": "Structure",
    "fonction": "Fonction",
    "genre_du_contact": "Genre du contact",
    "tel_perso": "Phone perso",
    "email_perso": "Email perso",
    "tel_pro": "Phone pro",
    "email_pro": "Email pro",
    "tel_autre": "Phone other",
    "email_autre": "Email other",
    "site_web": "Website",
    "oeuvres_detenues" : "Oeuvres détenues",
    "oeuvres_detenues_passe" : "Oeuvres passées",
    "create_new_contact" : "Create new contact contact",
    "invitation": "Invitation",
    "date_acquisition" : "Acquisition date",
    // DESSIN
    "dessin": "Drawing",
    "dessins": "Drawings",


    // EXPO
    "expo": "Exhibition",
    "expos": "Exhibitions",
    "annee_debut": "Start year",
    "annee_fin": "End year",
    "date_approximative": "Approx date",
    "lieu" : "Place",
    "type_expo": "Exhibition type",
    "dossier_dexpo" : "Exhibition file",
    "participants": "Participants",
    "commissaire_curator" : "Commissioner / Curator",

    // MEDIA
    "media": "Media",
    "medias": "Medias",
    "source": "Source",
    "format": "Format",
    "genre": "Kind",
    "langue": "Language",
    "langue_son" : "Language / sound",
    "sous_titre": "Subtitle",
    "langue_soustitre": "Subtitle language",
    "duree": "Length",
    "support_original": "Original support",
    "date_de_parution" : "Release date",
    "timecode" : "Timecode",

    // PHOTO
    "photo": "Photo",
    "photos": "Photos",
    "photographe": "Photograph",
    "localisation du support original": "Locating the original media",
    "identification personnes / oeuvres": "Identification of people / artworks",
    "localisation_exposition" : "Location / Exhibition",
    "code_photo" : "Code photo",
}
