
import { insertImages } from "Util/Export"
import { getStringSurfaceDiameter, getStringSurfaceRect, typeOeuvreString } from 'Util/Oeuvre';
import { displayOeuvreLastProprioLight } from 'Util/OeuvreProprietaire';


    export const exportListeInterne = async (wb, ws, oeuvres) => {

        ws.columns = [
            { header: 'Archive', key: 'archive', width: 10 },
            { header: 'Titre', key: 'titre', width: 40 },
            { header: 'Année', key: 'annee', width: 10 },
            { header: 'Hauteur', key: 'hauteur', width: 5 },
            { header: 'Largeur', key: 'largeur', width: 5 },
            { header: 'Profondeur', key: 'profondeur', width: 5 },
            { header: 'Technique', key: 'technique', width: 30 },
            { header: 'Photo', key: 'filename', width: 20 },
        ];

        oeuvres.forEach(oeuvre => {
            const row = ws.addRow([
                oeuvre.archive,
                oeuvre.titre,
                oeuvre.annee_creation,
                oeuvre.hauteur,
                oeuvre.largeur,
                oeuvre.profondeur,
                oeuvre.details_techniques,
                oeuvre.filename
            ])
            row.bold = false
            row.height = 110;
        })
        
        await insertImages(wb, ws, oeuvres, 7)
        return "Liste simple interne"
    }

    export const exportListeExterne = async (wb, ws, oeuvres) => {

        ws.columns = [
            { header: 'Titre', key: 'titre', width: 40 },
            { header: 'Année', key: 'annee', width: 10 },
            { header: 'Hauteur', key: 'hauteur', width: 5 },
            { header: 'Largeur', key: 'largeur', width: 5 },
            { header: 'Profondeur', key: 'profondeur', width: 5 },
            { header: 'Technique', key: 'technique', width: 30 },
            { header: 'Photo', key: 'filename', width: 20 },
        ];
 
        oeuvres.forEach(oeuvre => {
            const row = ws.addRow([
                oeuvre.titre,
                oeuvre.annee_creation,
                oeuvre.hauteur,
                oeuvre.largeur,
                oeuvre.profondeur,
                oeuvre.details_techniques,
                oeuvre.filename
            ])
            row.bold = false
            row.height = 110;
        })

        await insertImages(wb, ws, oeuvres, 6)
        return "Liste simple externe"
    }


    export const exportRegisseurInterne = async (wb, ws, oeuvres, withArchive) => {

        ws.columns = [
            { header: 'Archive', key: 'archive', width: 10 },
            { header: 'Titre', key: 'titre', width: 40 },
            { header: 'Année', key: 'annee', width: 10 },
            { header: 'Type d\'oeuvre', key: 'type', width: 20 },
            { header: 'Exemplaire', key: 'type', width: 20 },
            { header: 'Edition', key: 'type', width: 20 },
            { header: 'Série', key: 'type', width: 20 },
            { header: 'Hauteur', key: 'hauteur', width: 10 },
            { header: 'Largeur', key: 'largeur', width: 10 },
            { header: 'Profondeur', key: 'profondeur', width: 10 },
            { header: 'Technique', key: 'technique', width: 30 },
            { header: 'Photo', key: 'filename', width: 20 },
            { header: 'Photo HD', key: 'photo_hd', width: 10 },
            { header: 'Fiche technique', key: 'fiche_technique', width: 10 },
            { header: 'Caisse', key: 'caisse', width: 10 },
            { header: 'Nb caisses', key: 'nb_caisses', width: 10 },
            { header: 'Dimension caisses', key: 'dim_caisses', width: 20 },
            { header: 'Localisation caisses', key: 'loc_caisses', width: 20 },
            { header: 'Etat', key: 'etat', width: 10 },
        ];
 
        var images = []

        oeuvres.forEach(oeuvre => {

            const type_oeuvre = typeOeuvreString(oeuvre.type)
            const photo_hd = oeuvre.photo_hd === 1 ? "Oui" : "Non"
            const fiche_technique = oeuvre.fiche_technique === 1 ? "Oui" : "Non"
            const caisse = oeuvre.caisse_transports.length > 0 ? "Oui" : "Non"
            const nb_caisses = oeuvre.caisse_transports.length
            var dimensions_caisses = ""
            var etat_caisses = ""

            if ( nb_caisses > 0 ) {
                oeuvre.caisse_transports.forEach( caisse => {

                    var dimension = ""

                    if ( caisse["largeur"] ) {
                        dimension = caisse["largeur"] + " x "
                    } else {
                        dimension = " ... x "
                    }
                    if ( caisse["profondeur"] ) {
                        dimension = dimension + caisse["profondeur"] + " x "
                    } else {
                         dimension = dimension + "... x "
                    }
                    if ( caisse["hauteur"] ) {
                        dimension = dimension + caisse["hauteur"]
                    } else {
                        dimension = dimension + "..."
                    }

                    dimensions_caisses = dimensions_caisses + dimension + ", "
                    if ( caisse["etat"] ) {
                        etat_caisses = etat_caisses + caisse.etat + ", "
                    }

                })
            }
            var row;

            if ( oeuvre.multiples.length > 0 ) {

                oeuvre.multiples.forEach( multiple => {
                 row = ws.addRow([
                        oeuvre.archive,
                        oeuvre.titre,
                        oeuvre.annee_creation,
                        type_oeuvre,
                        multiple.numero,
                        oeuvre.editer_par,
                        oeuvre.serie,
                        oeuvre.hauteur,
                        oeuvre.largeur,
                        oeuvre.profondeur,
                        oeuvre.details_techniques,
                        "",
                        photo_hd,
                        fiche_technique,
                        caisse,
                        nb_caisses,
                        dimensions_caisses,
                        multiple.derniere_localisation,
                        etat_caisses
                    ])
                    row.bold = false
                    row.height = 110;
                    var newItem = {}

                    newItem["photo_items"] =  oeuvre["photo_items"]
                    images.push(newItem )
                })
            } else {
                row = ws.addRow([
                    oeuvre.archive,
                    oeuvre.titre,
                    oeuvre.annee_creation,
                    type_oeuvre,
                    "",
                    "",
                    "",
                    oeuvre.hauteur,
                    oeuvre.largeur,
                    oeuvre.profondeur,
                    oeuvre.details_techniques,
                    "",
                    photo_hd,
                    fiche_technique,
                    caisse,
                    nb_caisses,
                    dimensions_caisses,
                    oeuvre.derniere_localisation,
                    etat_caisses
                ])
                row.bold = false
                row.height = 110;

                var newItem = {}

                newItem["photo_items"] =  oeuvre["photo_items"]
                images.push(newItem )
            }

        })
        await insertImages(wb, ws, images, 11)

        return "Liste régisseur interne"
    }


    export const exportRegisseurExterne = async (wb, ws, oeuvres) => {

         ws.columns = [
            { header: 'Archive', key: 'archive', width: 10 },
            { header: 'Titre', key: 'titre', width: 40 },
            { header: 'Année', key: 'annee', width: 10 },
            { header: 'Type d\'oeuvre', key: 'type', width: 20 },
            { header: 'Exemplaire', key: 'type', width: 20 },
            { header: 'Edition', key: 'type', width: 20 },
            { header: 'Série', key: 'type', width: 20 },
            { header: 'Hauteur', key: 'hauteur', width: 10 },
            { header: 'Largeur', key: 'largeur', width: 10 },
            { header: 'Profondeur', key: 'profondeur', width: 10 },
            { header: 'Technique', key: 'technique', width: 30 },
            { header: 'Photo', key: 'filename', width: 20 },
            { header: 'Photo HD', key: 'photo_hd', width: 10 },
            { header: 'Fiche technique', key: 'fiche_technique', width: 10 },
            { header: 'Caisse', key: 'caisse', width: 10 },
            { header: 'Nb caisses', key: 'nb_caisses', width: 10 },
            { header: 'Dimension caisses', key: 'dim_caisses', width: 20 },
            { header: 'Localisation caisses', key: 'loc_caisses', width: 20 },
            { header: 'Etat', key: 'etat', width: 10 },
        ];

        var images = []

        oeuvres.forEach(oeuvre => {

            const type_oeuvre = typeOeuvreString(oeuvre.type)
            const photo_hd = oeuvre.photo_hd === 1 ? "Oui" : "Non"
            const fiche_technique = oeuvre.fiche_technique === 1 ? "Oui" : "Non"
            const caisse = oeuvre.caisse_transports.length > 0 ? "Oui" : "Non"
            const nb_caisses = oeuvre.caisse_transports.length
            var dimensions_caisses = ""
            var etat_caisses = ""

            if ( nb_caisses > 0 ) {
                oeuvre.caisse_transports.forEach( caisse => {

                    var dimension = ""

                    if ( caisse["largeur"] ) {
                        dimension = caisse["largeur"] + " x "
                    } else {
                        dimension = " ... x "
                    }
                    if ( caisse["profondeur"] ) {
                        dimension = dimension + caisse["profondeur"] + " x "
                    } else {
                         dimension = dimension + "... x "
                    }
                    if ( caisse["hauteur"] ) {
                        dimension = dimension + caisse["hauteur"]
                    } else {
                        dimension = dimension + "..."
                    }

                    dimensions_caisses = dimensions_caisses + dimension + ", "
                    if ( caisse["etat"] ) {
                        etat_caisses = etat_caisses + caisse.etat + ", "
                    }

                })
            }
            var row;

            if ( oeuvre.multiples.length > 0 ) {

                oeuvre.multiples.forEach( multiple => {
                 row = ws.addRow([
                        oeuvre.titre,
                        oeuvre.annee_creation,
                        type_oeuvre,
                        multiple.numero,
                        oeuvre.editer_par,
                        oeuvre.serie,
                        oeuvre.hauteur,
                        oeuvre.largeur,
                        oeuvre.profondeur,
                        oeuvre.details_techniques,
                        "",
                        photo_hd,
                        fiche_technique,
                        caisse,
                        nb_caisses,
                        dimensions_caisses,
                        multiple.derniere_localisation,
                        etat_caisses
                    ])
                    row.bold = false
                    row.height = 110;
                    var newItem = {}

                    newItem["photo_items"] =  oeuvre["photo_items"]
                    images.push(newItem )
                })
            } else {
                row = ws.addRow([
                    oeuvre.titre,
                    oeuvre.annee_creation,
                    type_oeuvre,
                    "",
                    "",
                    "",
                    oeuvre.hauteur,
                    oeuvre.largeur,
                    oeuvre.profondeur,
                    oeuvre.details_techniques,
                    "",
                    photo_hd,
                    fiche_technique,
                    caisse,
                    nb_caisses,
                    dimensions_caisses,
                    oeuvre.derniere_localisation,
                    etat_caisses
                ])
                row.bold = false
                row.height = 110;
                var newItem = {}

                newItem["photo_items"] =  oeuvre["photo_items"]
                images.push(newItem )
            }

        })

        await insertImages(wb, ws, images, 10)
        return "Liste régisseur externe"
    }


    export const  exportMultiplesDisponibles = async (wb, ws, multiples) => {

        ws.columns = [
            { header: 'Titre', key: 'titre', width: 40 },
            { header: 'Numéro d\'exemplaire', key: 'exemplaire', width: 15 },
            { header: 'Edition', key: 'edition,', width: 20 },
            { header: 'Série', key: 'serie', width: 20 },
            { header: 'Hauteur', key: 'hauteur', width: 10 },
            { header: 'Largeur', key: 'largeur', width: 10 },
            { header: 'Profondeur', key: 'profondeur', width: 10 },
            { header: 'Localisation', key: 'localisation', width: 30 },
            { header: 'Emplacement', key: 'emplacement', width: 30 },
            { header: 'Disponibilité', key: 'disponibilite', width: 30 },
            { header: 'Etat de conservation', key: 'etat_conservation', width: 10 },
        ];
 
        console.log("multiples: ", multiples)
        multiples.forEach( multiple => {

            const row = ws.addRow([
                multiple.titre,
                multiple.numero,
                multiple.editer_par,
                multiple.serie,
                multiple.hauteur,
                multiple.largeur,
                multiple.profondeur,
                multiple.derniere_localisation,
                multiple.emplacement,
                multiple.etat,
                multiple.etat_de_conservation])
            
            row.bold = false

        })

        return "Liste multiples disponibles"
    }


    export const exportValeurAssurance = async (wb, ws, oeuvres) => {

        ws.columns = [
            { header: 'Titre', key: 'titre', width: 40 },
            { header: 'Année', key: 'annee', width: 10 },
            { header: 'Hauteur', key: 'hauteur', width: 10 },
            { header: 'Largeur', key: 'largeur', width: 10 },
            { header: 'Profondeur', key: 'profondeur', width: 10 },
            { header: 'Propriétaire', key: 'proprietaire', width: 40 },
            { header: 'Localisation', key: 'localisation', width: 50 },
            { header: 'Valeur d\'assurance', key: 'assurance', width: 15 },
        ];
 
        oeuvres.forEach(oeuvre => {

            console.log("oeuvre: ", oeuvre)
            console.log("oeuvre dernier_proprietaire: ", oeuvre.dernier_proprietaire)

            var assurance = ""
            var dernier_proprietaire = displayOeuvreLastProprioLight(oeuvre)

            if ( oeuvre.assurances.length > 0) {
                assurance = Intl.NumberFormat('us-US').format(oeuvre.assurances[0]["valeur"]) + " " +  oeuvre.assurances[0]["devise"]
                assurance = assurance.replaceAll(",", ".")
            }

           const row = ws.addRow([
                oeuvre.titre,
                oeuvre.annee_creation,
                oeuvre.hauteur,
                oeuvre.largeur,
                oeuvre.profondeur,
                dernier_proprietaire,
                oeuvre.derniere_localisation,
                assurance,
            ])
            row.bold = false
            row.height = 40;
        })

        return "Liste Assurance"
    }   
    
    export const exportPrix = async (wb, ws, oeuvres) => {

        ws.columns = [
            { header: 'Archive', key: 'archive', width: 10 },
            { header: 'Titre', key: 'titre', width: 30 },
            { header: 'Année', key: 'annee', width: 10 },
            { header: 'Hauteur', key: 'hauteur', width: 10 },
            { header: 'Largeur', key: 'largeur', width: 10 },
            { header: 'Profondeur', key: 'profondeur', width: 10 },
            { header: 'Surface', key: 'surface', width: 10 },
            { header: 'Prix', key: 'prix', width: 10 },
            { header: 'Photo', key: 'filename', width: 20 },
        ];
 
        oeuvres.forEach(oeuvre => {

             const surface = oeuvre.diametre ? getStringSurfaceDiameter(oeuvre) : getStringSurfaceRect(oeuvre)

            const row = ws.addRow([
                oeuvre.archive,
                oeuvre.titre,
                oeuvre.annee_creation,
                oeuvre.hauteur,
                oeuvre.largeur,
                oeuvre.profondeur,
                surface,
                oeuvre.prix,
                ""
            ])
            row.bold = false
            row.height = 110;
        })

        await insertImages(wb, ws, oeuvres, 8)
        return "Liste prix"
    } 

        
    export const exportCollectionneur = async (wb, ws, oeuvres) => {

        ws.columns = [
            { header: 'Archive', key: 'archive', width: 10 },
            { header: 'Titre', key: 'titre', width: 40 },
            { header: 'Année', key: 'annee', width: 10 },
            { header: 'Hauteur', key: 'hauteur', width: 5 },
            { header: 'Largeur', key: 'largeur', width: 5 },
            { header: 'Profondeur', key: 'profondeur', width: 5 },
            { header: 'Technique', key: 'technique', width: 30 },
            { header: 'Photo', key: 'filename', width: 20 },
            { header: 'Collectionneur', key: 'collectionneur', width: 30 },
        ];
 
        oeuvres.forEach(oeuvre => {
            const row = ws.addRow([
                oeuvre.archive,
                oeuvre.titre,
                oeuvre.annee_creation,
                oeuvre.hauteur,
                oeuvre.largeur,
                oeuvre.profondeur,
                oeuvre.details_techniques,
                oeuvre.filename,
                oeuvre.dernier_proprietaire
            ])
            row.bold = false
            row.height = 120;
        })

        await insertImages(wb, ws, oeuvres, 7)
        return "Liste collectionneur"
    }

    export const exportSansPhoto = async (wb, ws, oeuvres) => {

        ws.columns = [
            { header: 'Archive', key: 'archive', width: 10 },
            { header: 'Titre', key: 'titre', width: 40 },
            { header: 'Année', key: 'annee', width: 10 },
            { header: 'Hauteur', key: 'hauteur', width: 5 },
            { header: 'Largeur', key: 'largeur', width: 5 },
            { header: 'Profondeur', key: 'profondeur', width: 5 },
        ];
 
        oeuvres.forEach(oeuvre => {
            const row = ws.addRow([
                oeuvre.archive,
                oeuvre.titre,
                oeuvre.annee_creation,
                oeuvre.hauteur,
                oeuvre.largeur,
                oeuvre.profondeur
            ])
            row.bold = false
        })

        return "Liste sans photo"
    }

    export const exportInventaire = async (wb, ws, oeuvres) => {

        ws.columns = [
            { header: 'Archive', key: 'archive', width: 10 },
            { header: 'Titre', key: 'titre', width: 40 },
            { header: 'Année', key: 'annee', width: 10 },
            { header: 'Hauteur', key: 'hauteur', width: 5 },
            { header: 'Largeur', key: 'largeur', width: 5 },
            { header: 'Profondeur', key: 'profondeur', width: 5 },
            { header: 'Localisation', key: 'localisation', width: 20 },
        ];
 
        oeuvres.forEach(oeuvre => {
            const row = ws.addRow([
                oeuvre.archive,
                oeuvre.titre,
                oeuvre.annee_creation,
                oeuvre.hauteur,
                oeuvre.largeur,
                oeuvre.profondeur,
                oeuvre.derniere_localisation
            ])
            row.bold = false
        })

        return "Liste inventaire"
    }