import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    Button,
    Grid,
} from '@material-ui/core'

import Tabs, {
    TabsContainer, TabsPanels
} from 'components/Tabs'

import PageLayout from 'layouts/Helpers/PageLayout'
import Form from 'components/Form';
import Prompt from 'components/Prompt';

import DescriptionPanel from './Description';

import ConsignationsPanel from '../../Consignation/SubPanel';
import ExposPanel from '../../Expo/SubPanel/index_oeuvre';
import PhotosPanel from '../../Photo/SubPanel/index_oeuvre';
import DessinsPanel from '../../Dessin/SubPanel/index_oeuvre';
import BiblioPanel from '../../Biblio/SubPanel/index_oeuvre';
import MediasPanel from '../../Media/SubPanel/index_oeuvre';

import { deleteOeuvre, createPhotoItem } from 'Api'
import { useCurrentPermissionProfile } from 'redux/hooks';
import { isOeuvreMultiple } from 'Util/Oeuvre'


const FormContainer = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

type Props = {
    initialData: any,
    saveData: any,
    onSaved: any,
    reloadData: any => any,
    layout: 'edit' | 'create' | 'search'
}

const defaultTitle = "Nouvelle oeuvre";

const buttonsStyle = {
      paddingBottom: 20
    };
    
const Layout = ({ initialData, reloadData = () => {}, onChange = () => {}, onSaved = async () => {}, onDelete = async () => {}, saveData = async () => {}, layout = 'edit' }: Props) => {

    const { t } = useTranslation()

    const [ changed, setChanged ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ data, _setData ] = useState(initialData || {})
    const { enqueueSnackbar } = useSnackbar();


    useEffect(() => {
        _setData(initialData || {});
    }, [ initialData ])


    async function deleteOeuvreAsync(oeuvre) {
        await deleteOeuvre({ oeuvre });
        onDelete()
    }

    const onClickDelete = () => {

        if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
            deleteOeuvreAsync(data)
        }
    }

    const onClickCancel = () => {
        _setData(initialData || {});
        setChanged(false);
    }
    
    const onClickSave = async () => {
        if (!saveData) {
            return;
        }
        setLoading(true);
        let result;
        try {

            let photo_items = (data.photo_items || []);

            const createPhotoPromises = photo_items.map(async (photo_item, index) => {
                if ( photo_item ) {
                    if (!photo_item.objectid) {
                        const { objectId } = await createPhotoItem({ data: photo_item })
                        photo_items[index] = {
                            objectid: objectId,
                            ...photo_item
                        };
                    }
                }
            })
            await Promise.all(createPhotoPromises);


            result = await saveData({ ...data, photo_items })
            enqueueSnackbar("Sauvegardé", {
                variant: 'success'
            })
            setChanged(false);

        } catch (e) {
            alert("une erreur est survenue: " + e.reason)
        }
        setLoading(false);
        onSaved(result);
    }

    const canSave = () => {
        if (!changed || loading) {
            return false;
        }

        return true;
    }

    const setData = data => {
        setChanged(true);
        _setData(data);
    }

    const layoutIsEdit = layout === 'edit';
    const permissionProfile = useCurrentPermissionProfile();

    const allowedToDelete = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === null;
    const allowedToSave = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === 'adriana' || permissionProfile === 'avila' || permissionProfile === 'regisseur' || permissionProfile === null;
    const canSeeConsignations = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === 'adriana' || permissionProfile === 'avila' || permissionProfile === 'regisseur' || permissionProfile === null;
    const canSeeDessin = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === null;

    const isMultiple = isOeuvreMultiple(data)

    let titre = ""

    if ( data.titre ) {
        titre = data.titre
    }
    return (
        
        <PageLayout title={"# " + data.archive + " " + titre || (layoutIsEdit ? "" : defaultTitle)} actions={(

            <div style={buttonsStyle}>

            {saveData && allowedToSave && <>
                    <Button
                        disabled={!canSave()} color="primary"
                        onClick={onClickCancel} style={{marginRight: '10px'}}
                    >{t("cancel").toUpperCase()}</Button>
                    <Button
                        style={{marginRight: '10px'}}
                        disabled={!canSave()} variant="contained" color="primary"
                        onClick={onClickSave}
                    >{t("save").toUpperCase()}</Button>
                </>}
             { allowedToDelete && <Button
                    style={{marginLeft: '100px'}}
                    color="primary"
                     variant="contained"
                    onClick={onClickDelete}
                >{t("delete").toUpperCase()}</Button>}
            </div>


        )}>

            <Prompt when={changed===true} message={t("alerte_change_page")}/>

            <TabsContainer orientation={"horizontal"}>

                {!layoutIsEdit &&
                    <TabsPanels>
                        <DescriptionPanel reloadData={reloadData} layout={layout} data={data} onChange={setData} />
                    </TabsPanels>
                }

                {layoutIsEdit && <Tabs
                    orientation={"horizontal"}
                    variant="scrollable"
                    initialIndex={0}
                    tabs={[
                        {
                            label: t("description"),
                            panel: (
                                <TabsPanels>
                                    <DescriptionPanel reloadData={reloadData} layout={layout} data={data} onChange={setData} />
                                </TabsPanels>
                            )
                        },

                        {
                            label: t("expos"),
                            panel: (
                                <TabsPanels>
                                    <ExposPanel layout={layout} reloadData={reloadData} data={data}  onChange={setData}/>
                                </TabsPanels>
                            )
                        },

                        {
                            label: t("biblio_presse"),
                            panel: (
                                <TabsPanels>
                                    <BiblioPanel layout={layout} reloadData={reloadData} data={data} onChange={setData}/>
                                </TabsPanels>
                            )
                        },

                        {
                            label: t("medias"),
                            panel: (
                                <TabsPanels>
                                    <MediasPanel layout={layout} reloadData={reloadData} data={data} onChange={setData}/>
                                </TabsPanels>
                            )
                        },

                        ...(canSeeConsignations ? [
                        {
                            label: t("consignations"),
                            panel: (
                                <TabsPanels>
                                    { !isMultiple && <Grid container>
                                        <Grid item xs={12}>
                                            <FormContainer>
                                                <Form form={[
                                                    { label: t(""), attr: "", type: "display", size: { label: 150, field: 900 } },
                                                    [
                                                        { label: t("reserve"), attr: "statut_reserve", type: "checkbox", size: { label: 0, field: 100 } },
                                                        { label: t(""), attr: "status_reserve_date", type: "dateselector", size: { label: 0, field: 150 } },
                                                        { label: t("galerie"), attr: "status_reserve_galerie", type: "text", size: { label: 100, field: 300 } }
                                                    ],

                                                ]} data={data} onChange={setData} />
                                            </FormContainer>
                                        </Grid>
                                    </Grid>}

                                    <ConsignationsPanel layout={layout} data={data} onChange={setData} />
                                </TabsPanels>
                            )
                        },] : []),

                        {
                            label: t("photos"),
                            panel: (
                                <TabsPanels>
                                    <PhotosPanel  layout={layout} data={data}  reloadData={reloadData} onChange={setData}/>
                                </TabsPanels>
                            )
                        },

                        ...(canSeeDessin ? [
                        {
                            label: t("dessins"),
                            panel: (
                                <TabsPanels>
                                    <DessinsPanel  layout={layout} data={data}  reloadData={reloadData} onChange={setData}/>
                                </TabsPanels>
                            )
                        },] : []),
                    ]}
                />}

            </TabsContainer>
        
        </PageLayout>

    )
}


export default ({ initialData, ...props } : Props) => {

    const key = initialData ? initialData.objectid : "create";
    return (
        <Layout {...props} initialData={initialData} key={key} />
    )
}